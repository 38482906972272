export const addFormAccountingRules = `/setting/accounting/accountingrules/addFormAccountingRules`;
export const detailAccountingRules = `/setting/accounting/accountingrules/detail`;
export const editFormAccountingRules = `/setting/accounting/accountingrules/editFormAccountingRules`;
export const formPrematureClosure = `/fixdeposit/prematureclosure`;
export const printestatement = `/member/saving-data-detail/print/e-Statement`;
export const loanListMenu = `/member/financing/menu`;
export const financingData = `/member/financing/menu/financingdata`;
export const organisation = `/setting/organisation/menu`;
export const settingproducts = `/setting/products/menu`;
export const settingaccounting = `/setting/accounting/menu`;
export const settingmobile = `/setting/mobile/menu`;
export const settingmessaging = `/setting/messaging/menu`;
export const settinguser = `/setting/user/menu`;
export const settingsecurity = `/setting/securitymanagement`;
export const settingexternal = `/setting/externalservice/menu`;
export const settingsystem = `/setting/system/menu`;
export const comingsoon = `/comingsoon`;
export const unauthorized = `/unauthorized`;
export const addFormJournalEntries = `/setting/accounting/journalentries/addjournalentries`;
export const listPaylater = `/member/list/paylater`;
export const settingMobileUserDetail = `/setting/detail/mobileUser`;
export const settingMobileUser = `/setting/mobileUser`;
export const settingInternalUser = `/setting/internaluser`;
export const addFormInternalUser = `/setting/internaluser/addFormInternalUser`;
export const settingDetailInternalUser = `/setting/internaluser/detail`;
export const editFormInternalUser = `/setting/internaluser/editFormInternalUser`;
export const chargesList = `/setting/charges`;
export const detailCharges = `/setting/charges/detail`;
export const AddCharges = `/setting/charges/add`;
export const EditCharges = `/setting/charges/edit`;

export const reportMemberInstallmentList = `/reports/reportmember/reportmemberinstallmentlist`;
export const reportPaylaterList = `/reports/reportpaylater`;
export const reportMemberAccPaylaterSummary = `/reports/reportpaylater/MemberAccountPaylaterSummary`;
export const reportMemberPaylaterSummary = `/reports/reportpaylater/MemberPaylaterSummary`;
export const ListQrMerchant = `/setting/organisation/qrmerchant`;
export const DetailQrMerchant = `/member/merchant/detail/qrmerchant`;//`/setting/organisation/qrmerchant/detail`;
export const searchJournalEntries = `/setting/accounting/searchjournalentries`;
export const searchJournalEntriesDetail = `/setting/accounting/searchjournalentries/detail`;
export const printAgreementLetter = `/member/financing-data-detail/agreementletter`;
export const printTransactionReceipt = `/member/financing-data-detail/transactionreceipt`;
export const createGlRecurring = `/accounting/glrecurring/create`;
export const sweepaccount = `/sweepaccount`;
export const autosweep = `/sweepaccount/autosweep`;
export const autosweepdetail = `/sweepaccount/autosweep/detail`;
export const addautosweep = `/sweepaccount/autosweep/add/autosweep`;
export const editautosweep = `/sweepaccount/autosweep/edit/autosweep`;
export const autosweepgroup = `/setting/product/savings/autosweepgroup`;
export const menuSettingSavings = `/setting/product/savings`;
export const autosweepgroupdetail = `/setting/product/savings/autosweepgroup/detail`;
export const addautosweepgroup = `/setting/product/savings/autosweepgroup/addautosweepgroup`;
export const editautosweepgroup = `/setting/product/savings/autosweepgroup/editautosweepgroup`;
export const autosweepbulkexception = `/sweepaccount/autosweep/bulkexception`;
export const autosweepbulk = `/sweepaccount/autosweep/create/autosweepbulk`;
export const updateautosweepbulk = `/sweepaccount/autosweep/update/autosweepbulk`;
export const reportAutoSweep = `/reports/reportsavings/reportautosweep`;
export const menublockedaccount = `/member/blockedaccount/list`;
export const addblockedaccount = `/member/blockedaccount/add`;
export const detailblockedaccount = `/member/blockedaccount/detail`;
export const editblockedaccount = `/member/blockedaccount/edit`;
export const menupromotionmanagement = `/setting/mobile/promotionmanagement`;
export const detailmenupromotionmanagement = `/setting/mobile/promotionmanagement/detail`;
export const addmenupromotionmanagement = `/setting/mobile/promotionmanagement/add`;
export const editmenupromotionmanagement = `/setting/mobile/promotionmanagement/edit`;
export const memberEstatmenet = `/member/e-statement`;
export const listsubmenubillermanagement = `/setting/organisation/billermanagement/menu`;
export const depositbillerservice = `/setting/organisation/billermanagement/depositbillerservice`;
export const depositbillerservicedetail = `/setting/organisation/billermanagement/depositbillerservice/detail`;
export const adddepositbillerservice = `/setting/organisation/billermanagement/depositbillerservice/add`;
export const editdepositbillerservice = `/setting/organisation/billermanagement/depositbillerservice/edit`;
export const createdepositbiller = `/biller/depositbiller/list`;
export const adddepositbiller = `/biller/depositbiller/add`;
export const detaildepositbiller = `/biller/depositbiller/detail`;
export const depositbillermitra = `/setting/organisation/billermanagement/depositbillermitra`;
export const detaildepositbillermitra = `/setting/organisation/billermanagement/depositbillermitra/detail`;
export const adddepositbillermitra = `/setting/organisation/billermanagement/depositbillermitra/detail/add`;
export const prepayloan = `/member/financing/prepayfinancing`;
export const intermediary = `/UnitCoop/Intermediary`;//`/member/list/intermdiary`;
export const reportIntermediaryList = `/reports/reportintermediary`;
export const reportMemberIntermediarySummary = `/reports/reportintermediary/IntermediarySummary`;
export const reportMemberIntermediaryAccSummary = `/reports/reportintermediary/IntermediaryAccountSummary`;
export const menuagentgroup = `/setting/organisation/agencymanagement/agentgroup`;
export const submenuagencymanagement = `/setting/organisation/agencymanagement`;
export const agentgroupdetail = `/setting/organisation/agencymanagement/agentgroup/detail`;
export const addagentgroup = `/setting/organisation/agencymanagement/agentgroup/add`;
export const editagentgroup = `/setting/organisation/agencymanagement/agentgroup/edit`;
export const menuagent = `/member/agents/list`;//`/setting/organisation/agencymanagement/parameteragent`;
export const detailmenuagent = `/member/agents/detail`;//`/setting/organisation/agencymanagement/parameteragent/detail`;
export const addmenuagent = `/member/agents/add`;//`/setting/organisation/agencymanagement/parameteragent/add`;
export const editmenuagent = `/member/agents/edit`;//`/setting/organisation/agencymanagement/parameteragent/edit`;
export const menucollectionsheet = `/setting/organisation/agencymanagement/financingcollectionsheet`;
export const reportFinancingPaidOffDetails = `/reports/reportfinancings/reportfinancingpaidoffdetails`;
export const transferOverPaid = `/member/financing/transferoverpaid`;
export const requestfinancing = `/member/financing/requestfinancing`;
export const menuOffices = `/setting/organisation/offices`;
export const detailOffices = `/setting/organisation/offices/detail`;
export const addOffices = `/setting/organisation/offices/add`;
export const editOffices = `/setting/organisation/offices/edit`;
export const MenuExternalService = `/setting/system/externalservice`;
export const FormExternalServiceContactInfo = `/setting/system/externalservice/contactinfo`;
export const menupassbooktype = `/setting/products/passbookmanagement/savingpassbookmanagement/passbooktype`;
export const addpassbooktype = `/setting/products/passbookmanagement/savingpassbookmanagement/passbooktype/add`;
export const detailpassbooktype = `/setting/products/passbookmanagement/savingpassbookmanagement/passbooktype/detail`;
export const editpassbooktype = `/setting/products/passbookmanagement/savingpassbookmanagement/passbooktype/edit`;
export const printpassbookcover = `/member/savings/printpassbookcover`;
export const printpassbook = `/member/savings/printpassbook`;
export const reprintpassbook = `/member/savings/reprintpassbook`;
export const menureferralcode = `/setting/products/referralcode`;
export const addreferralcode = `/setting/products/referralcode/add`;
export const detailreferralcode = `/setting/products/referralcode/detail`;
export const editreferralcode = `/setting/products/referralcode/edit`;
export const menuCreateSettlementIncoming = `/biller/createsettlementincoming/list`;
export const formCreateSettlementIncoming = `/biller/createsettlementincoming/list/create`;
export const detailCreateSettlementIncoming = `/biller/createsettlementincoming/list/detail`;
export const reportBiller = `/reports/biller`;
export const reporttransactionbillerbymonth = `/reports/biller/transactionbillerbymonth`;
export const reporttransactionbillerbyyears = `/reports/biller/transactionbillerbyyears`;
export const reporttransactionbillerbytrxcode = `/reports/biller/transactionbillerbytrxcode`;
export const formEmploymentInformation = `/member/detail/employementinformation`;
export const addformEmploymentInformation = `/member/detail/addemployementinformation`;
export const reportactivefinancingByDisrbusalPeriod = `/reports/financing/activefinancingByDisrbusalPeriod`;
export const transactionMode = `/setting/accounting/transactionMode`;
export const addtransactionMode = `/setting/accounting/transactionMode/add`;
export const edittransactionMode = `/setting/accounting/transactionMode/edit`;
export const transactionCode = `/setting/accounting/transactionCode`;
export const addtransactionCode = `/setting/accounting/transactionCode/add`;
export const detailtransactionCode = `/setting/accounting/transactionCode/detail`;
export const edittransactionCode = `/setting/accounting/transactionCode/edit`;
export const producttemplate = `/setting/product/producttemplate`;
export const detailproducttemplate = `/setting/product/producttemplate/detail`;
export const addproducttemplate = `/setting/product/producttemplate/add`;
export const editproducttemplate = `/setting/product/producttemplate/edit`;
export const officeapproval = `/setting/organisation/offices/createofficeapproval`;
export const formtransactionCodecontrol = `/setting/accounting/transactionCodeControl`;
export const memberapproval = `/approval/memberapproval`;
export const memberapprovaldetail = `/approval/memberapproval/detail`;
export const listmemberactivation = `/approval/listmemberactivation`;
export const formdepositpaylater = `/paylater/FormDepositPaylater`;
export const formwithdrawpaylater = `/paylater/FormWithdrawalPaylater`;
export const reportMemberListing = `/reports/reportmember/report/reportmemberlisting`;
export const reportEmployeeListing = `/reports/reportmember/report/reportemployeelisting`;
export const reportMemberPaylaterBalance = `/reports/reportpaylater/MemberPaylaterBalance`;
export const menuFormUpdateSpecialRate = `/setting/product/savings/UpdateSpecialRate`;
export const menuFormResetSpecialRate = `/setting/product/savings/ResetSpecialRate`;
export const menuSavingsProduct = `/setting/savings/savingsproduct`;
export const AddSavingsProduct = `/setting/savings/savingsproduct/add`;
export const DetailSavingsProduct = `/setting/savings/savingsproduct/detail`;
export const EditSavingsProduct = `/setting/savings/savingsproduct/edit`;
export const menuUnitData = `/UnitCoop/UnitData`;
export const menuUnitDataDetail = `/UnitCoop/UnitData/Detail`;
export const menuAddUnitData = `/UnitCoop/UnitData/add`;
export const menuEditUnitData = `/UnitCoop/UnitData/edit`;
export const menuUnitUser = `/UnitCoop/UnitUser`;
export const menuAddUnitUser = `/UnitCoop/UnitUser/Add`;
export const menuDetailUnitUser = `/UnitCoop/UnitUser/detail`;
export const menuEditUnitUser = `/UnitCoop/UnitUser/edit`;
export const menuAccountingRules = `/setting/accounting/accountingrules`;
export const reportOpeningPaylater = `/reports/reportpaylater/OpeningPaylater`;
export const PaylaterDisbursementSummary = `/reports/reportpaylater/PaylaterDisbursementSummary`;
export const PaylaterDisbursement = `/reports/reportpaylater/PaylaterDisbursement`;
export const transactionKoperasiIn = `/reports/biller/transactionKoperasiIn`;
export const transactionKoperasiOut = `/reports/biller/transactionKoperasiOut`;
export const bulktransaction = `/dataimport/bulktransaction`;
export const transactionCommerces = `/reports/biller/transactionCommerces`;
export const transactionKoperasi = `/reports/biller/transactionKoperasi`;
export const reconciliationBiller = `/reports/biller/reconciliationBiller`;
export const mobileUserNotLogin = `/setting//user/menu/mobileUserNotLogin`;
export const reportRingkasanPengirimanPesan = `/reports/biller/ringkasanpengirimanpesan`;
export const reportActivationMember = `/reports/reportmember/ReportMemberTransactionActivity`;
export const passbookmanagement = `/setting/products/passbookmanagement`;
export const savingpassbookmanagement = `/setting/products/passbookmanagement/savingpassbookmanagement`;
export const financingpassbookmanagement = `/setting/products/passbookmanagement/financingpassbookmanagement`;
export const financingpassbook = `/setting/products/passbookmanagement/financingpassbookmanagement/financingpassbook`;
export const addfinacingpassbooktype = `/setting/products/passbookmanagement/financingpassbookmanagement/financingpassbook/add`;
export const detailfinacingpassbooktype = `/setting/products/passbookmanagement/financingpassbookmanagement/financingpassbook/detail`;
export const editfinacingpassbooktype = `/setting/products/passbookmanagement/financingpassbookmanagement/financingpassbook/edit`;
export const savingpassbookCover = `/setting/products/passbookmanagement/savingpassbookmanagement/passbookcover`;
export const resetpassbook = `/setting/products/passbookmanagement/savingpassbookmanagement/resetpassbook`;
export const reprintsavingpassbook = `/setting/products/passbookmanagement/savingpassbookmanagement/reprintpassbook`;
export const printsavingpassbook = `/setting/products/passbookmanagement/savingpassbookmanagement/printpassbook`;
export const financingpassbookcover = `/member/financing/menu/financingpassbook/financingpassbookcover`;
export const printfinancingpassbook = `/member/financing/menu/financingpassbook/printfinancingpassbook`;
export const resetfinancingpassbook = `/member/financing/menu/financingpassbook/resetfinancingpassbook`;
export const rePrintfinancingpassbook = `/member/financing/menu/financingpassbook/rePrintfinancingpassbook`;
export const financingpassbookmanagementLoan = `/member/financing/menu/financingpassbook`;
export const transactionLimit = `/setting/products/tellermanagement/tellerlimit`;
export const addtransactionLimit = `/setting/products/tellermanagement/tellerlimit/add`;
export const edittransactionLimit = `/setting/products/tellermanagement/tellerlimit/edit`;
export const tellermanagement = `/setting/products/tellermanagement`;
export const detailtransactionLimit = `/setting/products/tellermanagement/tellerlimit/detail`;
export const cashiertransaction = `/cashier/transaction`;
export const cashdeposit = `/cashier/transaction/cashdeposit`;
export const cashwithdrawal = `/cashier/transaction/cashwithdrawal`;
export const employmentlevel = `/setting/organisation/employmentlevel`;
export const addemploymentlevel = `/setting/organisation/employmentlevel/add`;
export const detailemploymentlevel = `/setting/organisation/employmentlevel/detail`;
export const editemploymentlevel = `/setting/organisation/employmentlevel/edit`;
export const cashgeneraltransaction = `/cashier/transaction/cashgeneraltransaction`;
export const telleraccount = `/setting/products/tellermanagement/telleraccount`;
export const addtelleraccount = `/setting/products/tellermanagement/telleraccount/add`;
export const detailtelleraccount = `/setting/products/tellermanagement/telleraccount/detail`;
export const edittelleraccount = `/setting/products/tellermanagement/telleraccount/edit`;
export const allocatecash = `/setting/products/tellermanagement/telleraccount/allocatecash`;
export const settlementcash = `/setting/products/tellermanagement/telleraccount/settlementcash`;
export const denomination = `/setting/products/tellermanagement/denomination`;
export const adddenomination = `/setting/products/tellermanagement/denomination/add`;
export const detaildenomination = `/setting/products/tellermanagement/denomination/detail`;
export const editdenomination = `/setting/products/tellermanagement/denomination/edit`;
export const reportTeller = `/reports/report/reportteller`;
export const reportcashallocation = `/reports/report/reportteller/reportcashallocation`;
export const reportcashsettlement = `/reports/report/reportteller/reportcashsettlement`;
export const reportdailycash = `/reports/report/reportteller/reportdailycash`;
export const overbookingteller = `/cashier/transaction/overbooking`;
export const menusavingpassbook = `/cashier/transaction/savingpassbook`;
export const transactiontellerapproval = `/cashier/transaction/transactionapproval`;
export const detailtransactiontellerapproval = `/cashier/transaction/transactionapproval/detail`;
export const menucashier = `/cashier/form`;
export const detailtelleroperation = `/cashier/form/telleroperation/detail`;
export const addtelleroperation = `/cashier/form/telleroperation/add`;
export const edittelleroperation = `/cashier/form/telleroperation/edit`;
export const settleteller = `/cashier/form/settle`;
export const allocateTeller = `/cashier/form/telleroperation/allocate`;
export const cashirgeneraltransaction = `/cashier/transaction/generaltransaction`;
export const menugeneraltransaction = `/accounting/generaltransaction`;
export const menuParamReportGLSummary = `/setting/accounting/reportparameterGLSummary`;
export const addParamReportGLSummary = `/setting/accounting/reportparameterGLSummary/add`;
export const detailParamReportGLSummary = `/setting/accounting/reportparameterGLSummary/detail`;
export const editParamReportGLSummary = `/setting/accounting/reportparameterGLSummary/edit`;
export const reportGLSummary = `/reports/reportaccounting/reportglsummary`;
export const reprintreceipt = `/member/savings/reprintreceipt`;
export const financingsubmission = `/approval/financingsubmission`;
export const reportsavingaccountopened = `/reports/reportsavings/reportsavingaccountopened`;
export const reportsavingaccountclosed = `/reports/reportsavings/reportsavingaccountclosed`;
export const PeriodicReport = `/reports/PeriodicReport`;
export const DetailPeriodicReport = `/accounting/PeriodicReport/detail`;
export const ViewCsv = `/view/csv`;
export const SearchGLRecurring = `/accounting/SearchGLRecurring`;
export const detailSearchGLRecurring = `/accounting/SearchGLRecurring/detail`;
export const reportSearchGLRecurring = `/accounting/SearchGLRecurring/detail/report`;
export const menuProductFinancing = `/setting/products/financing`;
export const addProductFinancing = `/setting/products/financing/add`;
export const detailProductFinancing = `/setting/products/financing/detail`;
export const editProductFinancing = `/setting/products/financing/edit`;
export const ReportMonthlyTermSavingMarginReport = `/reports/reporttermsavings/ReportMonthlyTermSavingMarginReport`;
export const ReportTermSavingAccrueWillBePaid = `/reports/reporttermsavings/ReportTermSavingAccrueWillBePaid`;
export const ReportTermSavingMarginDueReport = `/reports/reporttermsavings/ReportTermSavingMarginDueReport`;
export const ReportTermSavingWillBeMaturedReport = `/reports/reporttermsavings/ReportTermSavingWillBeMaturedReport`;
export const ReportTermSavingClosed = `/reports/reporttermsavings/ReportTermSavingClosed`;
export const ReportTermSavingMaintenance = `/reports/reporttermsavings/ReportTermSavingMaintenance`;
export const ReportTotalTermSavingAccount = `/reports/reporttermsavings/ReportTotalTermSavingAccount`;
export const detailRecurring = `/member/deposit-recurring/detail`;
export const menuRecurring = `/member/deposit-recurring/list`;
export const depositRecurring = `/member/deposit-recurring/deposit`;
export const CashierDepositRecurring = `/cashier/transaction/deposit-recurring`;
export const closeFormRecurring = `/member/deposit-recurring/detail/close`;
export const reportGLSummaryDaily = `/reports/reportaccounting/reportglsummarydaily`;
export const reportRecurring = `/reports/savingsplan`;
export const reportRecurringActive = `/reports/savingsplan/reportSavingPlanActive`;
export const reportRecurringDueDate = `/reports/savingsplan/reportSavingPlanDueDate`;
export const reportRecurringElapsed = `/reports/savingsplan/reportSavingPlanElapsed`;
export const reportRecurringCairTutup = `/reports/savingsplan/reportSavingPlanCairOrTutup`;
export const reportSavingPlanDepositDue = `/reports/savingsplan/reportSavingPlanDepositDue`;
export const reportSavingPlanList = `/reports/savingsplan/reportSavingPlanList`;
export const menuMerchant = `/setting/organisation/merchant`;
export const merchantList = `/member/merchant`;
export const addMerchantList = `/member/merchant/add`;
export const detailMerchantList = `/member/merchant/detail`;
export const merchantApproval = `/approval/merchantApproval`//`/setting/organisation/merchant/merchantApproval`;
export const merchantApprovalDetail = `/approval/merchantApproval/detail`//`/setting/organisation/merchant/merchantApproval/detail`;
export const editmerchantApproval = `/approval/merchantApproval/edit`//`/setting/organisation/merchant/merchantApproval/edit`;
export const merchantReSubmit = `/member/merchant/merchantReSubmit`;
export const merchantReSubmitForm = `/member/merchant/detail/ReSubmit`;
export const ReportRejectedFinancingRequest = `/reports/reportfinancings/ReportRejectedFinancingRequest`;
export const ReportTax = `/reports/reportaccounting/ReportTax`;
export const ReportTaxSummary = `/reports/reportaccounting/ReportTaxSummary`;
export const editRecurring = `/member/deposit-recurring/detail/edit-recurring`;
export const provosioningcriteria = `/setting/products/settingfinancing/provosioningcriteria`;
export const addprovosioningcriteria = `/setting/products/settingfinancing/provosioningcriteria/add`;
export const detailprovosioningcriteria = `/setting/products/settingfinancing/provosioningcriteria/detail`;
export const editprovosioningcriteria = `/setting/products/settingfinancing/provosioningcriteria/edit`;
export const settingfinancing = `/setting/products/settingfinancing`;
export const transferMember = `/member/detail/transferMember`;
export const approveTransferMember = `/member/detail/approveTransferMember`;
export const rejectTransferMember = `/member/detail/rejectTransferMember`;
export const editTokenService = `/setting/token/tokenservice/edit`;
export const menuSettingToken = `/setting/token`;
export const menuSettingTokenService = `/setting/token/tokenservice`;
export const addchargesSavings = `/member/saving-data-detail/addcharges`;
export const specialToken = `/token/specialToken`;
export const memberApproveRejectTransfer = `/approval/approvalTrasnfer`;
export const accountingJournal = `/accounting/accountingJournal`;
export const interBranchSettlement = `/accounting/interBranchSettlement`;
export const interBranchSettlementRequest = `/accounting/interBranchSettlement/interBranchSettlementRequest`;
export const addinterBranchSettlementRequest = `/accounting/interBranchSettlement/interBranchSettlementRequest/add`;
export const interBranchSettlementApprove = `/accounting/interBranchSettlement/interBranchSettlementApprove`;
export const editInterBranchSettlementApprove = `/accounting/interBranchSettlement/interBranchSettlementApprove/edit`;
export const interBranchSettlementConfirm = `/accounting/interBranchSettlement/interBranchSettlementConfirm`;
export const laporanPermintaanPelimpahanAntarCabang = `/reports/accounting/laporanPermintaanPelimpahanAntarCabang`;
export const laporanPersetujuanPelimpahanAntarCabang = `/reports/accounting/laporanPersetujuanPelimpahanAntarCabang`;
export const laporanPengirimanPelimpahanAntarCabang = `/reports/accounting/laporanPengirimanPelimpahanAntarCabang`;
export const laporanRekapPelimpahanAntarCabang = `/reports/accounting/laporanRekapPelimpahanAntarCabang`;
export const mobilemanagement = `/setting/mobile/mobilemenumanagement`;
export const mobilemenu = `/setting/mobile/mobilemenumanagement/mobilemenu`;
export const addmobilemenu = `/setting/mobile/mobilemenumanagement/mobilemenu/add`;
export const detailmobilemenu = `/setting/mobile/mobilemenumanagement/mobilemenu/detail`;
export const editmobilemenu = `/setting/mobile/mobilemenumanagement/mobilemenu/edit`;
export const mobilemenugroup = `/setting/mobile/mobilemenumanagement/mobilemenugroup`;
export const addmobilemenugroup = `/setting/mobile/mobilemenumanagement/mobilemenugroup/add`;
export const detailmobilemenugroup = `/setting/mobile/mobilemenumanagement/mobilemenugroup/detail`;
export const editmobilemenugroup = `/setting/mobile/mobilemenumanagement/mobilemenugroup/edit`;
export const mobilemenumitra = `/setting/mobile/mobilemenumanagement/mobilemenumitra`;
export const addmobilemenumitra = `/setting/mobile/mobilemenumanagement/mobilemenumitra/add`;
export const detailmobilemenumitra = `/setting/mobile/mobilemenumanagement/mobilemenumitra/detail`;
export const editmobilemenumitra = `/setting/mobile/mobilemenumanagement/mobilemenumitra/edit`;
export const detailmobilethememenumitra = `/setting/mobile/mobilemenumanagement/mobilemenumitra/detail/mobiletheme`;
export const addmobilethememenumitra = `/setting/mobile/mobilemenumanagement/mobilemenumitra/detail/mobiletheme/add`;
export const editmobilethememenumitra = `/setting/mobile/mobilemenumanagement/mobilemenumitra/detail/mobiletheme/edit`;
export const mobileversion = `/setting/mobile/mobileversion`;
export const mobilecommerce = `/setting/mobile/mobilecommerce`;
export const addmobilecommerce = `/setting/mobile/mobilecommerce/add`;
export const detailmobilecommerce = `/setting/mobile/mobilecommerce/detail`;
export const editmobilecommerce = `/setting/mobile/mobilecommerce/detail/edit`;
export const ReportTaxMember = `/reports/reportaccounting/ReportTaxMember`;
export const menuJobs = `/setting/system/jobs`;
export const detailJobs = `/setting/system/jobs/detail`;
export const historyJobs = `/setting/system/jobs/historyjobs`;
export const financingApprovalDetails = `/approval/financing-approval/details`;
export const financingApprovalDetailsApprove = `/approval/financing-approval/details/approve`;
export const financingApprovalDetailsReject = `/approval/financing-approval/details/reject`;
export const financingModifyApproval = `/approval/financing-approval/details/modify`;
export const menuApprovalManagement = `/setting/organisation/approvalManagement`;
export const ClientApprovalList = `/setting/organisation/approvalManagement/ClientApprovalList`;
export const LoanApprovalList = `/setting/organisation/approvalManagement/LoanApprovalList`;
export const userGroup = `/setting/organisation/approvalManagement/UserGroup`;
export const addUserGroup = `/setting/organisation/approvalManagement/UserGroup/addUserGroup`;
export const detailUserGroup = `/setting/organisation/approvalManagement/UserGroup/detail`;
export const editUserGroup = `/setting/organisation/approvalManagement/UserGroup/editUserGroup`;
export const menuproductgroup = `/setting/mobile/productgroup`;
export const addproductgroup = `/setting/mobile/productgroup/add`;
export const detailproductgroup = `/setting/mobile/productgroup/detail`;
export const editproductgroup = `/setting/mobile/productgroup/detail/edit`;
export const reportLoanRepayment = `/reports/reportfinancings/reportLoanRepayment`;

//coopProfile
export const coopProfile = `/setting/organisation/coopProfile`;
export const menuPosition = coopProfile+'/position';
export const addPosition = menuPosition+'/add';
export const detailPosition = menuPosition+'/detail';
export const editPosition = detailPosition+'/edit';
export const menuStructure = coopProfile+'/structure';
export const addStructure = menuStructure+'/add';
export const detailStructure = menuStructure+'/detail';
export const editStructure = detailStructure+'/edit';

export const printLoanAgreementQrCode = `/member/financing-data-detail/printLoanAgreement`
export const overbookingIntermediary = `/member/list/intermdiary/overbooking`;
export const reportRincianAktivitasTransaksiAnggota = `/reports/reportmember/ReportDetailMemberTransactionActivity`;
export const transferUnit = menuUnitDataDetail+`/transferUnit`;
export const approveTransferUnit = menuUnitDataDetail+`/approveTransferUnit`;
export const rejectTransferUnit = menuUnitDataDetail+`/rejectTransferUnit`;
export const detailintermediary = intermediary+`/Detail`;
export const printPaymentDetail = `/member/financing-data-detail/PaymentDetail`;
export const printRepaymentSchedule = `/member/financing-data-detail/RepaymentSchedule`;
export const savingtoloan = `/cashier/transaction/savingtoloan`;
export const collectionAgent = `/member/CollectionAgent/list`;
export const detailcollectionsheet = menucollectionsheet+ `/detail`;
export const collectionAgentDetail = collectionAgent+ `/detail`;
export const requestToken = `/token/tokenservice/requestToken`;
export const detailLoanDisbursment = `/approval/financing-disbursment/detail`;
export const reportsavingstransaction = `/reports/reportsavings/reportsavingstransaction`;
export const bulktransactionHistory = `/dataimport/transactionhistory`;
export const baseListreportDataImport = `/reports/dataimport`;
export const reportDataImport = baseListreportDataImport+`/reportDataImport`;
export const reportagent = `/reports/reportagent`;
export const agentTransactionReport = `/reports/reportagent/agentTransactionReport`;
export const agentComissionReport = `/reports/reportagent/agentComissionReport`;
export const detailTokenTopUp = `/token/tokenservice/detailtopup`;
export const formatreports = `/reports/accounting/formatreports`;
export const financingapprovalsingle = `/approval/financing-approval-single`;
export const reportsavingsstatus = `/reports/reportsavings/reportsavingsstatus`;
export const monitoring = `/monitoring`;
export const baganakunbukubesar = `/reports/accounting/baganakunbukubesar`;
