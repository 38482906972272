import React, { Suspense, lazy, useEffect } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { useSelector, useDispatch } from 'react-redux';
import { authCheckState } from './store/actions';
/* loader component for Suspense*/
import PageLoader from './components/Common/PageLoader';
import Base from './components/Layout/Base';
import BasePage from './components/Layout/BasePage';
import * as pathmenu from './shared/pathMenu';
import ReportFinancingPaidOffDetails from './containers/Reports/Financings/reportFinancingPaidOffDetails';
// import BaseHorizontal from './components/Layout/BaseHorizontal';
import { useIdleTimer } from 'react-idle-timer'
import { useHistory } from 'react-router-dom';

/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props} />;

const Dashboard = lazy(() => import('./containers/Dashboard'));

// Auth
const Login = lazy(() => import('./components/Pages/Login'));
const Logout = lazy(() => import('./containers/Auth/Logout/Logout'));

//Access
const RoleManagement = lazy(() => import('./containers/AccessManagement/RoleManagement'));
const DetailRoleManagement = lazy(() => import('./containers/AccessManagement/RoleManagement/detailRole'));

// Reports
const ReportsMember = lazy(() => import('./containers/Reports/Member/index'));
const ReportsSavings = lazy(() => import('./containers/Reports/Savings/index'));
const ReportsMemberListing = lazy(() => import('./containers/Reports/reportsmember'));
const ReportsSavingsBalanceSummary = lazy(() => import('./containers/Reports/Savings/reportssavingbalancesummary'));
const ReportsSavingsList = lazy(() => import('./containers/Reports/Savings/reportsavinglist'));
const ReportsTermSavings = lazy(() => import('./containers/Reports/TermSavings/index'));
const ReportsTermSavingsList = lazy(() => import('./containers/Reports/TermSavings/reporttermsavinglist'));
const ReportsTermSavingsSummary = lazy(() => import('./containers/Reports/TermSavings/reporttermsavingsummary'));
const ReportsFinancies = lazy(() => import('./containers/Reports/Financings/index'));
const ReportsActiveFinancies = lazy(() => import('./containers/Reports/Financings/reportactivefinancings'));
const ReportsLoanPayment = lazy(() => import('./containers/Reports/Financings/reportloanpayment'));
const ReportsFinancingCollecbility = lazy(() => import('./containers/Reports/Financings/financingCollecbility'));
const ReportsFinancingProvosioning = lazy(() => import('./containers/Reports/Financings/reportfinancingprovisioning'));
const ReportsMonthlyLoanInstallment = lazy(() => import('./containers/Reports/Financings/reportmonthlyloaninstallment'));
const ReportsAccounting = lazy(() => import('./containers/Reports/Accounting/index'));
const ReportsAccountingTrialBalance = lazy(() => import('./containers/Reports/Accounting/reportTrialBalance'));
const ReportsAccountingIncomeStatement = lazy(() => import('./containers/Reports/Accounting/reportsincomestatement'));
const ReportsAccountingBalanceSheet = lazy(() => import('./containers/Reports/Accounting/reportbalancesheet'));
const ReportsGeneralLedger = lazy(() => import('./containers/Reports/Accounting/reportgeneralledger'));
const ReportsMonthlyInstalmentList = lazy(() => import('./containers/Reports/Member/reportMonthlyMemberInstallmentList'));
const ReportsPaylater = lazy(() => import('./containers/Reports/Paylater'));
const ReportsMemberAccountPaylaterSummary = lazy(() => import('./containers/Reports/Paylater/reportAccPaylaterSummary'));
const ReportsMemberPaylaterSummary = lazy(() => import('./containers/Reports/Paylater/reportPaylaterSummary'));
const ReportsAutoSweep = lazy(() => import('./containers/Reports/Savings/reportautosweep'));
const ReportsIntermediary = lazy(() => import('./containers/Reports/Intermediary'));
const ReportsMemberIntermediarySummary = lazy(() => import('./containers/Reports/Intermediary/reportIntermediarySummary'));
const ReportsMemberIntermediaryAccSummary = lazy(() => import('./containers/Reports/Intermediary/reportAccIntermediarySummary'));
const ReportsAgentList = lazy(() => import('./containers/Reports/Agent'));
const transactionAgentReport = lazy(() => import('./containers/Reports/Agent/transactionAgentReport'));
const agentComissionReport = lazy(() => import('./containers/Reports/Agent/agentComissionReport'));


// Members
// const MemberData = lazy(() => import('./containers/Members/MemberData/index'));
const MemberData = lazy(() => import('./containers/Members/MemberData/indexpaging'));
const MemberDataAdd = lazy(() => import('./containers/Members/MemberData/add'));
const MemberDataDetail = lazy(() => import('./containers/Members/MemberData/detail'));
const MemberDataEdit = lazy(() => import('./containers/Members/MemberData/edit'));

const FormSavings = lazy(() => import('./containers/Transactions/Saving/Deposit/FormDeposit'));
const FormMaintenanceSavings = lazy(() => import('./containers/Members/SavingData/maintenanceForm'));
const FormWithdrawal = lazy(() => import('./containers/Transactions/Saving/Withdrawal/FormWithdrawal'));
const ListSavingData = lazy(() => import('./containers/Transactions/Saving/listSavingPaging'));
const ListFixDepositData = lazy(() => import('./containers/Transactions/Deposit'));
const SavingDataDetail = lazy(() => import('./containers/Members/SavingData/detail'));
const DepositDataDetail = lazy(() => import('./containers/Members/DepositData/detail'));
const LoanDataDetail = lazy(() => import('./containers/Members/LoanData/detailv2'));
const MobileUser = lazy(() => import('./containers/Members/MobileUser'));
const MobileUserDetail = lazy(() => import('./containers/Members/MobileUser/detail'));
const LoanData = lazy(() => import('./containers/Members/Loan'));
const LoanDetail = lazy(() => import('./containers/Members/Loan/detail'));
const LoanNeedApproval = lazy(() => import('./containers/Approval/LoanApproval'));
const LoanNeedDisbursment = lazy(() => import('./containers/Approval/LoanDisbursment'));

const DepositBiller = lazy(() => import('./containers/Biller/DepositBiller/formDepositBiller'));
const SettlementVA = lazy(() => import('./containers/Biller/SettlementVA/formSettlementVA'));
const TokenService = lazy(() => import('./containers/Token/TokenService'));

const Register = lazy(() => import('./components/Pages/Register'));
const Recover = lazy(() => import('./components/Pages/Recover'));
const Lock = lazy(() => import('./components/Pages/Lock'));
const NotFound = lazy(() => import('./components/Pages/NotFound'));
const Error500 = lazy(() => import('./components/Pages/Error500'));
const Maintenance = lazy(() => import('./components/Pages/Maintenance'));
const Home = lazy(() => import('./containers/Home'));
// Merchant
const MerchantList = lazy(() => import('./containers/Merchant'));

//Accounting
const CoaList = lazy(() => import('./containers/Accounting/COA/pageCOA'));
const CoaDetail = lazy(() => import('./containers/Accounting/COA/detail'));
const AddFormCoa = lazy(() => import('./containers/Accounting/COA/addFormCOA'));
const EditFormCoa = lazy(() => import('./containers/Accounting/COA/editFormCoa'));

const AddFrequentPosting = lazy(() => import('./containers/Accounting/FrequentPosting/addFormFrequentPosting'));
const accountingaccrulesList = lazy(() => import('./containers/Setting/Accounting/Accounting Rules'));
const addformaccrules = lazy(() => import('./containers/Setting/Accounting/Accounting Rules/addFormAccountingRules'));
const detailaccrules = lazy(() => import('./containers/Setting/Accounting/Accounting Rules/detail'));
const editformaccrules = lazy(() => import('./containers/Setting/Accounting/Accounting Rules/EditFormAccountingRules'));
const formPrematureClosure = lazy(() => import('./containers/Members/DepositData/formPrematureClose'));

const printestatement = lazy(() => import('./containers/Members/SavingData/Print/e-statement'));
const comingsoon = lazy(() => import('./containers/Home/comingsoon'));
const unauthorized = lazy(() => import('./containers/Home/unauthorized'));
const listMenuLoan = lazy(() => import('./containers/Members/Loan/LoanListMenu'));
const listMenuOrganisation = lazy(() => import('./containers/Setting/Organisation'));
const listOrganisationBillerManagement = lazy(() => import('./containers/Setting/Organisation/BillerManagement'));
const listMenuSettingProducts = lazy(() => import('./containers/Setting/Products'));
const listMenuSettingAccounting = lazy(() => import('./containers/Setting/Accounting'));
const listMenuSettingMobile = lazy(() => import('./containers/Setting/Mobile'));
const listMenuSettingMessaging = lazy(() => import('./containers/Setting/MessagingManagement'));
const listMenuSettingUserManagement = lazy(() => import('./containers/Setting/UserManagement'));
const listMenuSettingSecurityManagement = lazy(() => import('./containers/Setting/SecurityManagement'));
const listMenuSettingExternalService = lazy(() => import('./containers/Setting/ExternalService'));
const listMenuSettingSystem = lazy(() => import('./containers/Setting/System'));

const ErrorAuth = lazy(() => import('./components/Pages/ErrorAuth'));

// const UserManagementIndex = lazy(() => import('./containers/Setting/UserManagement'));
const SecurityManagementIndex = lazy(() => import('./containers/Setting/SecurityManagement'));
const WebMenuManagementIndex = lazy(() => import('./containers/Setting/SecurityManagement/WebMenuManagement'));
const AddWebMenuManagementForm = lazy(() => import('./containers/Setting/SecurityManagement/WebMenuManagement/AddFormWebMenu'));
const DetailWebMenuManagement = lazy(() => import('./containers/Setting/SecurityManagement/WebMenuManagement/detail'));
const EditWebMenuManagement = lazy(() => import('./containers/Setting/SecurityManagement/WebMenuManagement/EditFormWebMenu'));
const WebMenuPermissionIndex = lazy(() => import('./containers/Setting/SecurityManagement/WebMenuManagement/MenuPermissionParameter'));
const DetailWebMenuPermission = lazy(() => import('./containers/Setting/SecurityManagement/WebMenuManagement/MenuPermissionParameter/detail'));
const AddJournalEntries = lazy(() => import('./containers/Accounting/JournalEntries/addJournalEntries'));
const ListInternalUser = lazy(() => import('./containers/Setting/UserManagement/InternalUser'));
const AddFormInternalUser = lazy(() => import('./containers/Setting/UserManagement/InternalUser/addFormInternalUser'));
const DetailInternalUser = lazy(() => import('./containers/Setting/UserManagement/InternalUser/detail'));
const EditFormInternalUser = lazy(() => import('./containers/Setting/UserManagement/InternalUser/editFormInternalUser'));
const SearchJournalEntries = lazy(() => import('./containers/Accounting/JournalEntries/searchJournalEntries'));
const SearchJournalEntriesDetail = lazy(() => import('./containers/Accounting/JournalEntries/searchJournalEntriesDetail'));
const PrintAgreementLetter = lazy(() => import('./containers/Members/Loan/printAgreementLetter'));
const PrintTransactionReceipt = lazy(() => import('./containers/Members/Loan/prinTransactionReceipt'));

const MenuSweepAccountList = lazy(() => import('./containers/Members/SweepAccount'));
const MenuAutoSweep = lazy(() => import('./containers/Members/SweepAccount/AutoSweep/indexPaging'));
const AutoSweepDetail = lazy(() => import('./containers/Members/SweepAccount/AutoSweep/detail'));
const AddAutoSweep = lazy(() => import('./containers/Members/SweepAccount/AutoSweep/addFormAutoSweep'));
const EditAutoSweep = lazy(() => import('./containers/Members/SweepAccount/AutoSweep/editFromAutoSweep'));

const MenuSettingsSavings = lazy(() => import('./containers/Setting/Products/Savings'));
const MenuAutoSweepGroup = lazy(() => import('./containers/Setting/Products/Savings/AutoSweepGroup'));
const MenuAutoSweepGroupDetail = lazy(() => import('./containers/Setting/Products/Savings/AutoSweepGroup/detail'));
const AddAutoSweepGroup = lazy(() => import('./containers/Setting/Products/Savings/AutoSweepGroup/addFormAutoSweetGroup'));
const EditAutoSweepGroup = lazy(() => import('./containers/Setting/Products/Savings/AutoSweepGroup/editFormAutoSweepGroup'));
const MenuAutoSweepBulkException = lazy(() => import('./containers/Members/SweepAccount/AutoSweepBulkException'));
const MenuCreateAutoSweepBulk = lazy(() => import('./containers/Members/SweepAccount/AutoSweep/createAutoSweepBulkFrom'));
const MenuUpdateAutoSweepBulk = lazy(() => import('./containers/Members/SweepAccount/AutoSweep/updateAutoSweepBulkForm'));

const ListQrMerchant = lazy(() => import('./containers/Setting/Organisation/QrMerchant'));
const DetailQrMerchant = lazy(() => import('./containers/Setting/Organisation/QrMerchant/detail'));

const CreateGLRecurring = lazy(() => import('./containers/Accounting/RecurringJournalEntries/createRecurringJournalEntries'));
const ListPaylater = lazy(() => import('./containers/Members/Paylater'));

const ListCharges = lazy(() => import('./containers/Setting/Charges'));
const DetailCharges = lazy(() => import('./containers/Setting/Charges/detail'));
const AddCharges = lazy(() => import('./containers/Setting/Charges/addFormCharges'));
const EditCharges = lazy(() => import('./containers/Setting/Charges/editFormCharges'));

const MenuBlockedAccount = lazy(() => import('./containers/Members/BlockingAccount'));
const AddBlockedAccount = lazy(() => import('./containers/Members/BlockingAccount/addBlockingAccount'));
const DetailBlockedAccount = lazy(() => import('./containers/Members/BlockingAccount/detail'));
const EditBlockedAccount = lazy(() => import('./containers/Members/BlockingAccount/editBlockingAccount'));
const MenuPromotionManagement = lazy(() => import('./containers/Setting/Organisation/PromotionManagement'));
const DetailMenuPromotionManagement = lazy(() => import('./containers/Setting/Organisation/PromotionManagement/detail'));
const AddMenuPromotionManagement = lazy(() => import('./containers/Setting/Organisation/PromotionManagement/addFormPromotionManagement'));
const EditMenuPromotionManagement = lazy(() => import('./containers/Setting/Organisation/PromotionManagement/editFormPromotionManagement'));
const MemberEStatement = lazy(() => import('./containers/Members/MemberData/print/member_e-statement'));
const DepositBillerService = lazy(() => import('./containers/Setting/Organisation/BillerManagement/DepositBillerService'));
const DepositBillerServiceDetail = lazy(() => import('./containers/Setting/Organisation/BillerManagement/DepositBillerService/detail'));
const AddDepositBillerService = lazy(() => import('./containers/Setting/Organisation/BillerManagement/DepositBillerService/addFormDepositBillerService'));
const EditDepositBillerService = lazy(() => import('./containers/Setting/Organisation/BillerManagement/DepositBillerService/editFormDepositBillerService'));
const DepositBillerNew = lazy(() => import('./containers/Biller/DepositBiller'));
const AddDepositBillerNew = lazy(() => import('./containers/Biller/DepositBiller/addFormDepositBiller'));
const detailDepositBillerNew = lazy(() => import('./containers/Biller/DepositBiller/detail'));

const MenuDepositBillerMitra = lazy(() => import('./containers/Setting/Organisation/BillerManagement/DepositBillerMitra'));
const DetailDepositBillerMitra = lazy(() => import('./containers/Setting/Organisation/BillerManagement/DepositBillerMitra/detail'));
const AddDepositBillerMitra = lazy(() => import('./containers/Setting/Organisation/BillerManagement/DepositBillerMitra/addFormDepositBillerMitra'));
const prepayLoan = lazy(() => import('./containers/Members/Loan/prepayLoanForm'));
const MenuIntermediary = lazy(() => import('./containers/Members/Intermediary'));
const ReportsFinancingPaidOffDetails = lazy(() => import('./containers/Reports/Financings/reportFinancingPaidOffDetails'));
const TransferOverPaidLoan = lazy(() => import('./containers/Members/Loan/transferLoanOverPaidForm'));
const FormRequestFinancing = lazy(() => import('./containers/Members/Loan/requestFinancing'));
const MenuOffices = lazy(() => import('./containers/Setting/Organisation/Office'));
const DetailOffices = lazy(() => import('./containers/Setting/Organisation/Office/detail'));
const AddOffices = lazy(() => import('./containers/Setting/Organisation/Office/addFormOffices'));
const EditOffices = lazy(() => import('./containers/Setting/Organisation/Office/editFormOffices'));
const MenuExternalService = lazy(() => import('./containers/Setting/System/ExternalService'));
const FormExternalServiceContactInfo = lazy(() => import('./containers/Setting/System/ExternalService/formContactInfo'));
const menuReferralCode = lazy(() => import('./containers/Setting/Products/ReferralCode'));
const addReferralCode = lazy(() => import('./containers/Setting/Products/ReferralCode/addFormReferralCode'));
const detailReferralCode = lazy(() => import('./containers/Setting/Products/ReferralCode/detail'));
const editReferralCode = lazy(() => import('./containers/Setting/Products/ReferralCode/editFormReferralCode'));

const SubMenuAgencyManagement = lazy(() => import('./containers/Setting/Organisation/AgencyManagement'));
const MenuAgentGroup = lazy(() => import('./containers/Setting/Organisation/AgencyManagement/AgentGroup'));
const AgentGroupDetail = lazy(() => import('./containers/Setting/Organisation/AgencyManagement/AgentGroup/detail'));
const AddAgentGroup = lazy(() => import('./containers/Setting/Organisation/AgencyManagement/AgentGroup/addFormAgentGroup'));
const EditAgentGroup = lazy(() => import('./containers/Setting/Organisation/AgencyManagement/AgentGroup/editFormAgentGroup'));
const MenuAgent = lazy(() => import('./containers/Setting/Organisation/AgencyManagement/ParamterAgent'));
const DetailMenuAgent = lazy(() => import('./containers/Setting/Organisation/AgencyManagement/ParamterAgent/detail'));
const AddMenuAgent = lazy(() => import('./containers/Setting/Organisation/AgencyManagement/ParamterAgent/addFormParameterAgent'));
const EditMenuAgent = lazy(() => import('./containers/Setting/Organisation/AgencyManagement/ParamterAgent/editFormParameterAgent'));

const MenuLoanCollectionSheet = lazy(() => import('./containers/Setting/Organisation/AgencyManagement/LoanColectionSheet/indexGridList'));
const MenuPassbookType = lazy(() => import('./containers/Setting/Products/PassbookType'));
const addPassbookType = lazy(() => import('./containers/Setting/Products/PassbookType/formAddPassbookType'));
const detailPassbookType = lazy(() => import('./containers/Setting/Products/PassbookType/detail'));
const editPassbookType = lazy(() => import('./containers/Setting/Products/PassbookType/formEditPassbookType'));
const printPassbookCover = lazy(() => import('./containers/Members/SavingData/Print/pasbookCover'));
const printPassbook = lazy(() => import('./containers/Members/SavingData/Print/printPassbook'));
const rePrintPassbook = lazy(() => import('./containers/Members/SavingData/Print/rePrintPassbook'));

const MenuCreateSettlementIncoming = lazy(() => import('./containers/Biller/Settlement'));
const FormCreateSettlementIncoming = lazy(() => import('./containers/Biller/Settlement/createFormSettlementIncoming'));
const DetailCreateSettlementIncoming = lazy(() => import('./containers/Biller/Settlement/detail'));
const reportsBiller = lazy(() => import('./containers/Reports/Biller'));
const reportsTransBillerByMonth = lazy(() => import('./containers/Reports/Biller/reportTransactionBillerByMonths'));
const reportsTransBillerByYears = lazy(() => import('./containers/Reports/Biller/reportTransactionBillerByYear'));
const reportsTransBillerByTrxCode = lazy(() => import('./containers/Reports/Biller/reportTransactionBillerByTrxCode'));
const formEmploymentInformation = lazy(() => import('./containers/Members/MemberData/EmployeeInformation'));
const addformEmploymentInformation = lazy(() => import('./containers/Members/MemberData/EmployeeInformation/addFormEmployeeInformation'));
const detailformEmploymentInformation = lazy(() => import('./containers/Members/MemberData/EmployeeInformation/detail'));
const editformEmploymentInformation = lazy(() => import('./containers/Members/MemberData/EmployeeInformation/editFormEmployeeInformation'));

const reportsActiveFinancingByDisbursalPeriod = lazy(() => import('./containers/Reports/Financings/activeFinancingByDisbursalPeriod'));
const ListTransactionMode = lazy(() => import('./containers/Setting/Accounting/TransactionMode'));
const AddTransactionMode = lazy(() => import('./containers/Setting/Accounting/TransactionMode/addTransansactionModeForm'));
const EditTransactionMode = lazy(() => import('./containers/Setting/Accounting/TransactionMode/editTransansactionModeForm'));

const ListTransactionCode = lazy(() => import('./containers/Setting/Accounting/TransactionCode'));
const AddTransactionCode = lazy(() => import('./containers/Setting/Accounting/TransactionCode/addTransactionCode'));
const detailTransactionCode = lazy(() => import('./containers/Setting/Accounting/TransactionCode/detail'));
const EditTransactionCode = lazy(() => import('./containers/Setting/Accounting/TransactionCode/editTransactionCode'));

const ProductTemplate = lazy(() => import('./containers/Setting/Products/ProductTemplate'));
const DetailProductTemplate = lazy(() => import('./containers/Setting/Products/ProductTemplate/detail'));
const addProductTemplate = lazy(() => import('./containers/Setting/Products/ProductTemplate/addFormProductTemplate'));
const editFormProductTemplate = lazy(() => import('./containers/Setting/Products/ProductTemplate/editFormProductTemplate'));

const FormListApproval = lazy(() => import('./containers/Setting/Organisation/Office/formListApprover'));
const FormTransactionCodeControl = lazy(() => import('./containers/Setting/Accounting/TransactionCodeControl/formTransactionCodeControl'));

const MemberApproval = lazy(() => import('./containers/Approval/MemberApproval'));
const MemberApprovalDetail = lazy(() => import('./containers/Approval/MemberApproval/detail'));
const ListMemberActivation = lazy(() => import('./containers/Approval/ListMemberActivation'));

const FormWithdrawalPaylater = lazy(() => import('./containers/Members/Paylater/FormWithdrawalPaylater'));
const FormDepositPaylater = lazy(() => import('./containers/Members/Paylater/FormDepositPaylater'));
const reportsMemberListingSimple = lazy(() => import('./containers/Reports/Member/reportMemberListingSimple'));
const reportEmployeeListingSimple = lazy(() => import('./containers/Reports/Member/reportEmployeeListingSimple'));
const reportMemberPaylaterBalance = lazy(() => import('./containers/Reports/Paylater/reportMemberPaylaterBalance'));

const MenuUpdateSpecialRate = lazy(() => import('./containers/Setting/Products/Savings/UpdateSpecialRate/formUpdateSpecialRate'));
const MenuResetSpecialRate = lazy(() => import('./containers/Setting/Products/Savings/ResetSpecialRate/formResetSpecialRate'));
const menuSavingsProduct = lazy(() => import('./containers/Setting/Products/Savings/SavingsProduct'));
const AddSavingsProduct = lazy(() => import('./containers/Setting/Products/Savings/SavingsProduct/addFormSavingsProduct'));
const EditSavingsProduct = lazy(() => import('./containers/Setting/Products/Savings/SavingsProduct/editFormSavingProduct'));
const DetailSavingsProduct = lazy(() => import('./containers/Setting/Products/Savings/SavingsProduct/detail'));

const menuUnitData = lazy(() => import('./containers/UnitCoop/UnitData'));
const menuAddUnitData = lazy(() => import('./containers/UnitCoop/UnitData/add/addFormUnitData'));
const menuEditUnitData = lazy(() => import('./containers/UnitCoop/UnitData/editFormUnitData'));

const menuUnitUser = lazy(() => import('./containers/UnitCoop/UnitUser'));
const menuAddUnitUser = lazy(() => import('./containers/UnitCoop/UnitUser/addFormUnitUser'));
const menuDetailUnitUser = lazy(() => import('./containers/UnitCoop/UnitUser/detail'));
const menuEditUnitUser = lazy(() => import('./containers/UnitCoop/UnitUser/editFormUnitUser'));

const reportOpeningPaylater = lazy(() => import('./containers/Reports/Paylater/reportOpeningPaylater'));
const reportPaylaterDisbursementSummary = lazy(() => import('./containers/Reports/Paylater/reportPaylaterDisbursementSummary'));
const reportPaylaterDisbursement = lazy(() => import('./containers/Reports/Paylater/reportPaylaterDisbursement'));
const reportsTransIn = lazy(() => import('./containers/Reports/Biller/reportTransactionAntarKoperasiIn'));
const reportsTransOut = lazy(() => import('./containers/Reports/Biller/reportTransactionAntarKoperasiOut'));

const menuBulkTransaction = lazy(() => import('./containers/DataImport/BulkTransaction'));
const reportTransactionCommerces = lazy(() => import('./containers/Reports/Biller/reportTransactionCommerces'));
const reportTransactionAntarKoperasi = lazy(() => import('./containers/Reports/Biller/reportTransactionAntarKoperasi'));
const reportReconciliationBiller = lazy(() => import('./containers/Reports/Biller/reportReconciliationBiller'));
const mobileUserNotLogin = lazy(() => import('./containers/Setting/Mobile/MobileUserNotLogin'));
const ReportRingkasanPengirimanPesan = lazy(() => import('./containers/Reports/Biller/reportRingkasanPengirimanPesan'));
const reportMemberTransactionActivity = lazy(() => import('./containers/Reports/Member/reportMemberTransactionActivity'));

const menuPassBookManagement = lazy(() => import('./containers/Setting/Products/PassBookManagement'));
const menuSavingPassBookManagement = lazy(() => import('./containers/Setting/Products/PassBookManagement/SavingPassBookManagement'));
const menuFinancingPassBookManagement = lazy(() => import('./containers/Setting/Products/PassBookManagement/FinancingPassBookManagement'));
const menuFinancingPassBook = lazy(() => import('./containers/Setting/Products/PassBookManagement/FinancingPassBookManagement/FinancingPassBook'));
const addFinancingPassBookType = lazy(() => import('./containers/Setting/Products/PassBookManagement/FinancingPassBookManagement/FinancingPassBook/addFormFinancingPassbookType'));
const detailFinancingPassBookType = lazy(() => import('./containers/Setting/Products/PassBookManagement/FinancingPassBookManagement/FinancingPassBook/detail'));
const editFormFinancingPassbookType = lazy(() => import('./containers/Setting/Products/PassBookManagement/FinancingPassBookManagement/FinancingPassBook/editFormFinancingPassbookType'));
const financingPassBookCover = lazy(() => import('./containers/Setting/Products/PassBookManagement/FinancingPassBookManagement/financingPassBookCover'));
const printFinancingPassBook = lazy(() => import('./containers/Setting/Products/PassBookManagement/FinancingPassBookManagement/printFinancingPassBook'));
const resetFinancingPassbook = lazy(() => import('./containers/Setting/Products/PassBookManagement/FinancingPassBookManagement/resetFinancingPassbook'));
const rePrintFinancingPassbook = lazy(() => import('./containers/Setting/Products/PassBookManagement/FinancingPassBookManagement/rePrintFinancingPassbook'));

const savingPassbookCover = lazy(() => import('./containers/Setting/Products/PassBookManagement/SavingPassBookManagement/passBookCover'));
const resetSavingPassbook = lazy(() => import('./containers/Setting/Products/PassBookManagement/SavingPassBookManagement/resetPassbook'));
const rePrintSavingPassbook = lazy(() => import('./containers/Setting/Products/PassBookManagement/SavingPassBookManagement/rePrintSavingPassbook'));
const printSavingPassbook = lazy(() => import('./containers/Setting/Products/PassBookManagement/SavingPassBookManagement/printSavingPassbook'));
const listMenuPassbookLoan = lazy(() => import('./containers/Members/Loan/listMenuPassbook'));

const menuTellerManagement = lazy(() => import('./containers/Setting/Products/TellerManagement'));
const menuTransLimit = lazy(() => import('./containers/Setting/Products/TellerManagement/TellerLimit'));
const addTelllerLimit = lazy(() => import('./containers/Setting/Products/TellerManagement/TellerLimit/addTelllerLimit'));
const editTellerLimit = lazy(() => import('./containers/Setting/Products/TellerManagement/TellerLimit/editTellerLimit'));
const detailTelllerLimit = lazy(() => import('./containers/Setting/Products/TellerManagement/TellerLimit/detail'));

const menuTransactionCashier = lazy(() => import('./containers/Cashier/Transaction'));
const menuCashDeposit = lazy(() => import('./containers/Cashier/Transaction/CashDeposit'));
const menuCashWithdrawal = lazy(() => import('./containers/Cashier/Transaction/CashWithdrawal'));
const menuCashGeneralTransaction = lazy(() => import('./containers/Cashier/Transaction/CashGeneralTransaction'));
const menuOverbookingTeller = lazy(() => import('./containers/Cashier/Transaction/Overbooking'));

const employmentLevel = lazy(() => import('./containers/Setting/Organisation/EmploymentLevel'));
const addEmployementLevel = lazy(() => import('./containers/Setting/Organisation/EmploymentLevel/addEmployementLevel'));
const detailEmployementLevel = lazy(() => import('./containers/Setting/Organisation/EmploymentLevel/detail'));
const editEmployementLevel = lazy(() => import('./containers/Setting/Organisation/EmploymentLevel/editEmployementLevel'));

const menuTellerAccount = lazy(() => import('./containers/Setting/Products/TellerManagement/TellerAccount'));
const addTellerAccount = lazy(() => import('./containers/Setting/Products/TellerManagement/TellerAccount/addTellerAccount'));
const detailTellerAccount = lazy(() => import('./containers/Setting/Products/TellerManagement/TellerAccount/detail'));
const editTellerAccount = lazy(() => import('./containers/Setting/Products/TellerManagement/TellerAccount/editTellerAccount'));
const allocateCash = lazy(() => import('./containers/Setting/Products/TellerManagement/TellerAccount/allocateCash'));
const settleCash = lazy(() => import('./containers/Setting/Products/TellerManagement/TellerAccount/settleCash'));

const menuDenomination = lazy(() => import('./containers/Setting/Products/TellerManagement/Denomination'));
const addDenomination = lazy(() => import('./containers/Setting/Products/TellerManagement/Denomination/addDenomination'));
const detailDenomination = lazy(() => import('./containers/Setting/Products/TellerManagement/Denomination/detail'));
const editDenomination = lazy(() => import('./containers/Setting/Products/TellerManagement/Denomination/editDenomination'));

const reportTeller = lazy(() => import('./containers/Reports/Teller'));
const reportCashAllocation = lazy(() => import('./containers/Reports/Teller/reportCashAllocation'));
const reportCashSettlement = lazy(() => import('./containers/Reports/Teller/reportCashSettlement'));
const reportDailyCash = lazy(() => import('./containers/Reports/Teller/reportDailyCash'));
const menuSavingPassbookTeller = lazy(() => import('./containers/Cashier/Transaction/savingPassbookMenu'));
const menuTransactionTellerApproval = lazy(() => import('./containers/Cashier/TransactionApproval'));
const detailTransactionTellerApproval = lazy(() => import('./containers/Cashier/TransactionApproval/detail'));

const menuCashier = lazy(() => import('./containers/Cashier/UI'));
const detailTellerOperation = lazy(() => import('./containers/Cashier/UI/TellerOperation/detail'));
const addTellerOperation = lazy(() => import('./containers/Cashier/UI/TellerOperation/addTellerAccount'));
const editTellerOperation = lazy(() => import('./containers/Cashier/UI/TellerOperation/editTellerAccount'));
const settleCashTeller = lazy(() => import('./containers/Cashier/UI/settleCash'));
const allocateCashTeller = lazy(() => import('./containers/Cashier/UI/TellerOperation/allocateCash'));
const cashierGeneralTransaction = lazy(() => import('./containers/Cashier/Transaction/GeneralTransaction'));
const menuGeneralTransaction = lazy(() => import('./containers/Accounting/GeneralTransaction'));

const menuReportParamGLSummaryTabelaris = lazy(() => import('./containers/Setting/Accounting/ReportParameterGLSummary'));
const addReportParamGLSummaryTabelaris = lazy(() => import('./containers/Setting/Accounting/ReportParameterGLSummary/addParameterGlSummary'));
const detailReportParamGLSummaryTabelaris = lazy(() => import('./containers/Setting/Accounting/ReportParameterGLSummary/detail'));
const editReportParamGLSummaryTabelaris = lazy(() => import('./containers/Setting/Accounting/ReportParameterGLSummary/editParameterGLSummary'));

const reportParamGLSummaryTabelaris = lazy(() => import('./containers/Reports/Accounting/reportGLSummaryTabelLaris'));
const reportParamGLSummaryTabelarisDaily = lazy(() => import('./containers/Reports/Accounting/reportGLSummaryTabelLarisDaily'));

const menuRePrintReceipt = lazy(() => import('./containers/Setting/Products/PassBookManagement/SavingPassBookManagement/RePrintReceipt'));

const menuLoanSubmission = lazy(() => import('./containers/Approval/LoanSubmission'));
const reportSavingsAccountOpened = lazy(() => import('./containers/Reports/Savings/reportSavingsAccountOpened'));
const reportSavingsAccountClosed = lazy(() => import('./containers/Reports/Savings/reportSavingsAccountClosed'));
const menuReportJob = lazy(() => import('./containers/Accounting/ReportJob'));
const detailReportJob = lazy(() => import('./containers/Accounting/ReportJob/detail'));
const viewCSV = lazy(() => import('./containers/Generate/CsvFile'));

const searchRecurringJournal = lazy(() => import('./containers/Accounting/SearchRecurringJournal/searchRecurringJournal'));
const detailSearchRecurringJournal = lazy(() => import('./containers/Accounting/SearchRecurringJournal/detail'));
const reportGLRecurringJournal = lazy(() => import('./containers/Accounting/SearchRecurringJournal/viewReport'));

const menuProductFinancing = lazy(() => import('./containers/Setting/Products/Financing'));
const addFinancingProduct = lazy(() => import('./containers/Setting/Products/Financing/addFinancingProduct'));
const detailFinancingProduct = lazy(() => import('./containers/Setting/Products/Financing/details'));
const editlFinancingProduct = lazy(() => import('./containers/Setting/Products/Financing/editFinancingProduct'));

const ReportMonthlyFixedDepositInterestReportRanged = lazy(() => import('./containers/Reports/TermSavings/reportMonthlyFixedDepositInterestReportRanged'));
const reportTermSavingAccrueWillBePaid = lazy(() => import('./containers/Reports/TermSavings/reportTermSavingAccrueWillBePaid'));
const reportTermSavingMarginDueReport = lazy(() => import('./containers/Reports/TermSavings/reportTermSavingMarginDueReport'));
const ReportTermSavingWillBeMaturedReport = lazy(() => import('./containers/Reports/TermSavings/reportTermSavingWillBeMaturedReport'));
const ReportTermSavingClosed = lazy(() => import('./containers/Reports/TermSavings/reportTermSavingClosed'));
const ReportTermSavingMaintenance = lazy(() => import('./containers/Reports/TermSavings/reportTermSavingMaintenance'));
const ReportTotalTermSavingAccount = lazy(() => import('./containers/Reports/TermSavings/reportTotalTermSavingAccount'));
const menuRecurring = lazy(() => import('./containers/Members/DepositData/Recurring'));
const detailRecurring = lazy(() => import('./containers/Members/DepositData/detailRecurring'));
const depositRecurring = lazy(() => import('./containers/Members/DepositData/Recurring/deposit'));
const closeFormRecurring = lazy(() => import('./containers/Members/DepositData/Recurring/closeForm'));
const cashierDepositRecurring = lazy(() => import('./containers/Cashier/Transaction/DepositRecurring/deposit'));

const ReportRecurring = lazy(() => import('./containers/Reports/Recurring'));
const ReportRecurringActive = lazy(() => import('./containers/Reports/Recurring/reportRecurringActive'));
const ReportRecurringDueDate = lazy(() => import('./containers/Reports/Recurring/reportTabunganBerjangkaJatuhTempo'));
const ReportRecurringElapsed = lazy(() => import('./containers/Reports/Recurring/reportTabunganBerjangkaElapsed'));
const ReportRecurringCairTutup = lazy(() => import('./containers/Reports/Recurring/reportTabunganBerjangkaCairOrTutup'));
const ReportSetoranJatuhTempo = lazy(() => import('./containers/Reports/Recurring/reportSetoranJatuhTempo'));
const ReportDaftarTabunganBerjangka = lazy(() => import('./containers/Reports/Recurring/reportDaftarTabunganBerjangka'));
const editRecurring = lazy(() => import('./containers/Members/DepositData/Recurring/editRecurring'));

const merchantMenus = lazy(() => import('./containers/Setting/Organisation/Merchant'));
const addMerchantList = lazy(() => import('./containers/Merchant/addForm'));
const detailMerchantList = lazy(() => import('./containers/Merchant/detail'));

const merchantApproval = lazy(() => import('./containers/Setting/Organisation/Merchant/MerchantApproval'));
const merchantApprovalDetail = lazy(() => import('./containers/Setting/Organisation/Merchant/MerchantApproval/detail'));
const editMerchant = lazy(() => import('./containers/Setting/Organisation/Merchant/MerchantApproval/editMerchant'));

// const merchantReSubmit = lazy(() => import('./containers/Setting/Organisation/Merchant/ReSubmit'));
const merchantReSubmitForm = lazy(() => import('./containers/Setting/Organisation/Merchant/ReSubmit/addForm'));

const ReportRejectedFinancingRequest = lazy(() => import('./containers/Reports/Financings/reportRejectedFinancingRequest'));
const LoanProvisioningParameter = lazy(() => import('./containers/Setting/Products/LoanProvisioningParameter'));
const AddLoanProvisioningParameter = lazy(() => import('./containers/Setting/Products/LoanProvisioningParameter/addForm'));
const DetailLoanProvisioningParameter = lazy(() => import('./containers/Setting/Products/LoanProvisioningParameter/detail'));
const EditLoanProvisioningParameter = lazy(() => import('./containers/Setting/Products/LoanProvisioningParameter/editForm'));

const MenuSettingFinancing = lazy(() => import('./containers/Setting/Products/Financing/indexListMenu'));
const ReportTax = lazy(() => import('./containers/Reports/Accounting/reportTax'));
const ReportTaxSummary = lazy(() => import('./containers/Reports/Accounting/reportTaxSummary'));

const MenuTransferMember = lazy(() => import('./containers/Members/MemberData/TransferMember'));
const approveTransferMember = lazy(() => import('./containers/Members/MemberData/approveTransferMember'));
const rejectTransferMember = lazy(() => import('./containers/Members/MemberData/rejectTransferMember'));
const EditTokenService = lazy(() => import('./containers/Setting/Token/TokenService/editForm'));
const MenuSettingToken = lazy(() => import('./containers/Setting/Token'));
const MenuSettingTokenService = lazy(() => import('./containers/Setting/Token/TokenService'));
const addChargesSaving = lazy(() => import('./containers/Members/SavingData/addCharges'));
const menuSpecialToken = lazy(() => import('./containers/Token/SpecialToken'));
const memberApproveRejectTransfer = lazy(() => import('./containers/Approval/MemberApproveRejectTransfer'));
const accountingJournal = lazy(() => import('./containers/Accounting/AccountingJournal'));

const interBranchSettlement = lazy(() => import('./containers/Accounting/InterBranchSettlement'));
const InterBranchSettlementRequest = lazy(() => import('./containers/Accounting/InterBranchSettlement/InterBranchSettlementRequest'));
const addInterBranchSettlementRequest = lazy(() => import('./containers/Accounting/InterBranchSettlement/InterBranchSettlementRequest/addForm'));
const InterBranchSettlementApprove = lazy(() => import('./containers/Accounting/InterBranchSettlement/InterBranchSettlementApprove'));
const editInterBranchSettlementApprove = lazy(() => import('./containers/Accounting/InterBranchSettlement/InterBranchSettlementApprove/editForm'));
const InterBranchSettlementConfirm = lazy(() => import('./containers/Accounting/InterBranchSettlement/InterBranchSettlementConfirm'));

const laporanPermintaanPelimpahanAntarCabang = lazy(() => import('./containers/Reports/Accounting/laporanPermintaanPelimpahanAntarCabang'));
const laporanPersetujuanPelimpahanAntarCabang = lazy(() => import('./containers/Reports/Accounting/laporanPersetujuanPelimpahanAntarCabang'));
const laporanPengirimanPelimpahanAntarCabang = lazy(() => import('./containers/Reports/Accounting/laporanPengirimanPelimpahanAntarCabang'));
const laporanRekapPelimpahanAntarCabang = lazy(() => import('./containers/Reports/Accounting/laporanRekapPelimpahanAntarCabang'));

const menuMobileManagement = lazy(() => import('./containers/Setting/Mobile/MobileMenuManagement'));
const mobileMenu = lazy(() => import('./containers/Setting/Mobile/MobileMenuManagement/MobileMenu'));
const addmobileMenu = lazy(() => import('./containers/Setting/Mobile/MobileMenuManagement/MobileMenu/addForm'));
const detailmobileMenu = lazy(() => import('./containers/Setting/Mobile/MobileMenuManagement/MobileMenu/detail'));
const editmobileMenu = lazy(() => import('./containers/Setting/Mobile/MobileMenuManagement/MobileMenu/editForm'));

const mobileMenuGroup = lazy(() => import('./containers/Setting/Mobile/MobileMenuManagement/MobileMenuGroup'));
const addmobileMenuGroup = lazy(() => import('./containers/Setting/Mobile/MobileMenuManagement/MobileMenuGroup/addForm'));
const detailmobileMenuGroup = lazy(() => import('./containers/Setting/Mobile/MobileMenuManagement/MobileMenuGroup/detail'));
const editmobileMenuGroup = lazy(() => import('./containers/Setting/Mobile/MobileMenuManagement/MobileMenuGroup/editForm'));

const mobileMenuMitra = lazy(() => import('./containers/Setting/Mobile/MobileMenuManagement/MobileMenuMitra'));
const addmobileMenuMitra = lazy(() => import('./containers/Setting/Mobile/MobileMenuManagement/MobileMenuMitra/addForm'));
const detailmobileMenuMitra = lazy(() => import('./containers/Setting/Mobile/MobileMenuManagement/MobileMenuMitra/detail'));
const editmobileMenuMitra = lazy(() => import('./containers/Setting/Mobile/MobileMenuManagement/MobileMenuMitra/editForm'));
const detailmobileThemeMenuMitra = lazy(() => import('./containers/Setting/Mobile/MobileMenuManagement/MobileMenuMitra/detailMobileTheme'));
const addmobileThemeMenuMitra = lazy(() => import('./containers/Setting/Mobile/MobileMenuManagement/MobileMenuMitra/addMobileTheme'));
const editMobileTheme = lazy(() => import('./containers/Setting/Mobile/MobileMenuManagement/MobileMenuMitra/editMobileTheme'));
const menuMobileVersion = lazy(() => import('./containers/Setting/Mobile/MobileVersion'));
const menuMobileCommerce = lazy(() => import('./containers/Setting/Mobile/MobileCommerce'));
const addMobileCommerce = lazy(() => import('./containers/Setting/Mobile/MobileCommerce/addForm'));
const detailMobileCommerce = lazy(() => import('./containers/Setting/Mobile/MobileCommerce/detail'));
const editMobileCommerce = lazy(() => import('./containers/Setting/Mobile/MobileCommerce/editForm'));
const ReportTaxMember = lazy(() => import('./containers/Reports/Accounting/reportTaxMember'));
const menuJobs = lazy(() => import('./containers/Setting/System/Jobs'));
const detailJobs = lazy(() => import('./containers/Setting/System/Jobs/detail'));
const historyJobs = lazy(() => import('./containers/Setting/System/Jobs/historyJobs'));

const financingApprovalDetails = lazy(() => import('./containers/Approval/LoanApproval/details'));
const financingApprovalApprove = lazy(() => import('./containers/Approval/LoanApproval/formApprove'));
const financingApprovalRejected = lazy(() => import('./containers/Approval/LoanApproval/formRejected'));
const modifyFinancingApproval = lazy(() => import('./containers/Approval/LoanApproval/modifyFinancing'));
const menuApprovalManagement = lazy(() => import('./containers/Setting/Organisation/ApprovalManagement'));

const menuClientApprovalList = lazy(() => import('./containers/Setting/Organisation/ApprovalManagement/ClientApprovelList'));
const menuLoanApprovalList = lazy(() => import('./containers/Setting/Organisation/ApprovalManagement/LoanApprovalList'));
const menuUserGroup = lazy(() => import('./containers/Setting/Organisation/ApprovalManagement/UserGroup'));
const addUserGroup = lazy(() => import('./containers/Setting/Organisation/ApprovalManagement/UserGroup/addForm'));
const detailUserGroup = lazy(() => import('./containers/Setting/Organisation/ApprovalManagement/UserGroup/detail'));
const editUserGroup = lazy(() => import('./containers/Setting/Organisation/ApprovalManagement/UserGroup/editForm'));
const menuProductGroup = lazy(() => import('./containers/Setting/Mobile/ProductGroup'));
const addProductGroup = lazy(() => import('./containers/Setting/Mobile/ProductGroup/addProductGroup'));
const detailProductGroup = lazy(() => import('./containers/Setting/Mobile/ProductGroup/detail'));
const editProductGroup = lazy(() => import('./containers/Setting/Mobile/ProductGroup/editProductGroup'));
const reportLoanRepayment = lazy(() => import('./containers/Reports/Financings/reportLoanRepayment'));

const coopProfile = lazy(() => import('./containers/Setting/Organisation/CoopProfile'));

const menuOrganisationPosition = lazy(() => import('./containers/Setting/Organisation/CoopProfile/OrganisationPosition'));
const addOrganisationPosition = lazy(() => import('./containers/Setting/Organisation/CoopProfile/OrganisationPosition/addPosition'));
const detailOrganisationPosition = lazy(() => import('./containers/Setting/Organisation/CoopProfile/OrganisationPosition/detail'));
const editOrganisationPosition = lazy(() => import('./containers/Setting/Organisation/CoopProfile/OrganisationPosition/editPosition'));

const menuOrganisationStructure = lazy(() => import('./containers/Setting/Organisation/CoopProfile/OrganisationStructure'));
const addOrganisationStructure = lazy(() => import('./containers/Setting/Organisation/CoopProfile/OrganisationStructure/addStructure'));
const detailOrganisationStructure = lazy(() => import('./containers/Setting/Organisation/CoopProfile/OrganisationStructure/detail'));
const editOrganisationStructure = lazy(() => import('./containers/Setting/Organisation/CoopProfile/OrganisationStructure/editStructure'));

const printLoanAgreementQrCode = lazy(() => import('./containers/Members/Loan/printLoanAgreementQrCode'));
const overbookingForm = lazy(() => import('./containers/Members/Intermediary/overbookingForm'));
const reportRincianAktivitasTransaksiAnggota = lazy(() => import('./containers/Reports/Member/reportRincianAktivitasTransaksiAnggota'));

const detailUnitData = lazy(() => import('./containers/UnitCoop/UnitData/detail'));
const transferUnit = lazy(() => import('./containers/UnitCoop/UnitData/transferUnit'));
const approveTransferUnit = lazy(() => import('./containers/UnitCoop/UnitData/approveTransferUnit'));
const rejectTransferUnit = lazy(() => import('./containers/UnitCoop/UnitData/rejectTransferUnit'));
const detailIntermediary = lazy(() => import('./containers/Members/Intermediary/detail'));

const savingToLoan = lazy(() => import('./containers/Cashier/Transaction/SavingToLoan'));
const collectionAgent = lazy(() => import('./containers/Members/CollectionAgent'));
const printpaymentDetails = lazy(() => import('./containers/Members/Loan/print/paymentDetails'));
const printrepaymentSchedule = lazy(() => import('./containers/Members/Loan/print/repaymentSchedule'));
const detailsCollectionSheet = lazy(() => import('./containers/Setting/Organisation/AgencyManagement/LoanColectionSheet/details'));
const collectionAgentDetail = lazy(() => import('./containers/Members/CollectionAgent/detail'));

const requestToken = lazy(() => import('./containers/Token/TokenService/requestToken'));
const detailLoanDisbursement = lazy(() => import('./containers/Approval/LoanDisbursment/detail'));
const detailTokenTopUp = lazy(() => import('./containers/Token/TokenService/detailsTopUp'));
const reportSavingsTransaction = lazy(() => import('./containers/Reports/Savings/reportSavingsTransaction'));
const reportFormatReports = lazy(() => import('./containers/Reports/Accounting/reportFormatReports'));

const loanApprovalSingle = lazy(() => import('./containers/Approval/LoanApprovalSingle'));
const reportSavingsAccountStatus = lazy(() => import('./containers/Reports/Savings/reportSavingsAccountStatus'));

const listHistoryBulk = lazy(() => import('./containers/DataImport/BulkTransaction/HistoryBulk/index'));
const listReportDataImport = lazy(() => import('./containers/Reports/DataImport'));
const reportDataImport = lazy(() => import('./containers/Reports/DataImport/reportDataImport'));
const historyBulkIdParam = lazy(() => import('./containers/DataImport/BulkTransaction/HistoryBulk/historyBulkIdParam'));
const tokenMonitoring = lazy(() => import('./containers/Monitoring/TokenMonitoring'));

const reportBaganAkunBukuBesar = lazy(() => import('./containers/Reports/Accounting/reportBaganAkunBukuBesar'));

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
     '/login',
     '/register',
     '/recover',
     '/lock',
     '/notfound',
     '/error500',
     '/maintenance',
     '/errorauth'
];

const Routes = ({ location, ...props }) => {
     const currentKey = location.pathname.split('/')[1] || '/';
     const timeout = { enter: 500, exit: 500 };
     let isAuthenticated = useSelector(state => state.auth.token !== null);
     let renepassword = useSelector(state => state.auth.shouldRenewPassword !== null && state.auth.shouldRenewPassword !== undefined ? state.auth.shouldRenewPassword ? false : true : true);
     let renepasswordhuntu = useSelector(state => state.auth.shouldRenewPassword);
     const error = useSelector(state => state.auth.error);
     const dispatch = useDispatch();
     const history = useHistory();
     const minutes = 15;

     const handleOnIdle = event => {
          console.log('user is idle', event)
          console.log('last active', getLastActiveTime())
          history.push('/logout');
     }

     const handleOnActive = event => {
          console.log('user is active', event)
          console.log('time remaining', getRemainingTime())
     }

     const handleOnAction = event => {
          // console.log('user did something', event)
          // let sessionuser = sessionStorage.getItem('sessionuser');
          // if(sessionuser == null || sessionuser == undefined){
          //        history.push('/logout');
          // }
          // console.log('Cookies ', sessionStorage.getItem('sessionuser'))
     }

     const { getRemainingTime, getLastActiveTime } = useIdleTimer({
          timeout: 1000 * 60 * minutes,
          onIdle: handleOnIdle,
          onActive: handleOnActive,
          onAction: handleOnAction,
          debounce: 500
     })


     useEffect(() => {
          dispatch(authCheckState());
          // if(!renepassword){
          //        dispatch(authCheckState());
          // }else{
          //        localStorage.removeItem('token');
          // }



          const currentLanguage = localStorage.getItem('simpool-lng');
          if (currentLanguage === undefined || currentLanguage === null)
               props.i18n.changeLanguage('en');
          else if (currentLanguage !== 'en')
               props.i18n.changeLanguage(currentLanguage);

          return () => {
          };
     }, []);

     if (!isAuthenticated && localStorage.getItem('token') !== null && error === null)
          isAuthenticated = true;

     let sessionuser = sessionStorage.getItem('sessionuser');
     if (sessionuser == null || sessionuser == undefined) {
          isAuthenticated = false;
          // history.push('/logout');
     }

     // Animations supported
     //      'rag-fadeIn'
     //      'rag-fadeInRight'
     //      'rag-fadeInLeft'
     const animationName = 'rag-fadeIn';
     //state.auth.shouldRenewPassword
     if (isAuthenticated && renepassword) {
          if (listofPages.indexOf(location.pathname) > -1) {
               return (
                    // Page Layout component wrapper
                    <BasePage>
                         <Suspense fallback={<PageLoader />}>
                              <Switch location={location}>
                                   <Route path="/login" render={() => <Redirect to={'/home'} />} />
                                   <Route path="/notfound" component={waitFor(NotFound)} />
                                   <Route path="/error500" component={waitFor(Error500)} />
                                   <Route path="/maintenance" component={waitFor(Maintenance)} />
                                   <Route exact path="/errorauth" component={waitFor(ErrorAuth)} />
                              </Switch>
                         </Suspense>
                    </BasePage>
               )
          }
          else {
               return (
                    // Layout component wrapper
                    // Use <BaseHorizontal> to change layout
                    <Base>
                         <TransitionGroup>
                              <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                                   <div>
                                        <Suspense fallback={<PageLoader />}>
                                             <Switch location={location}>
                                                  {/* Reports ReportsMemberListing  */}

                                                  <Route exact path="/reports/reportmember" component={waitFor(ReportsMember)} />
                                                  <Route exact path="/reports/reportmember/reportmemberlisting" component={waitFor(ReportsMemberListing)} />
                                                  <Route exact path="/reports/reportsavings" component={waitFor(ReportsSavings)} />
                                                  <Route exact path="/reports/reportsavings/reportsavingbalancesummary" component={waitFor(ReportsSavingsBalanceSummary)} />
                                                  <Route exact path="/reports/reportsavings/reportsavinglist" component={waitFor(ReportsSavingsList)} />
                                                  <Route exact path="/reports/reporttermsavings" component={waitFor(ReportsTermSavings)} />
                                                  <Route exact path="/reports/reporttermsavings/reporttermsavingslist" component={waitFor(ReportsTermSavingsList)} />
                                                  <Route exact path="/reports/reporttermsavings/reporttermsavingsummary" component={waitFor(ReportsTermSavingsSummary)} />
                                                  <Route exact path="/reports/reportfinancings" component={waitFor(ReportsFinancies)} />
                                                  <Route exact path="/reports/reportfinancings/reportactivefinancings" component={waitFor(ReportsActiveFinancies)} />
                                                  <Route exact path="/reports/reportfinancings/reportloanpayment" component={waitFor(ReportsLoanPayment)} />
                                                  <Route exact path="/reports/reportfinancings/reportmonthlyloaninstallment" component={waitFor(ReportsMonthlyLoanInstallment)} />
                                                  <Route exact path="/reports/reportfinancings/reportfinancingcollectibility" component={waitFor(ReportsFinancingCollecbility)} />
                                                  <Route exact path="/reports/reportfinancings/reportfinancingprovosioning" component={waitFor(ReportsFinancingProvosioning)} />

                                                  <Route exact path="/reports/reportaccounting" component={waitFor(ReportsAccounting)} />
                                                  <Route exact path="/reports/reportaccounting/reporttrialbalance" component={waitFor(ReportsAccountingTrialBalance)} />
                                                  <Route exact path="/reports/reportaccounting/reportincomestatement" component={waitFor(ReportsAccountingIncomeStatement)} />
                                                  <Route exact path="/reports/reportaccounting/reportbalancesheet" component={waitFor(ReportsAccountingBalanceSheet)} />
                                                  <Route exact path="/reports/reportaccounting/reportgeneraledger" component={waitFor(ReportsGeneralLedger)} />


                                                  {/* //Merchant */}
                                                  <Route exact path={pathmenu.merchantList} component={waitFor(MerchantList)} />

                                                  {/* Members */}
                                                  <Route exact path="/member" component={waitFor(MemberData)} />
                                                  <Route exact path="/member/add" component={waitFor(MemberDataAdd)} />
                                                  <Route exact path="/member/:id"
                                                       component={waitFor(MemberDataDetail)} />
                                                  <Route exact path="/member/edit/:id" component={waitFor(MemberDataEdit)} />

                                                  <Route exact path="/savings" component={waitFor(ListSavingData)} />
                                                  <Route exact path="/savings/FormSavings/:id/:accountnumber" component={waitFor(FormSavings)} />
                                                  <Route exact path="/savings/FormWithdrawal/:id/:accountnumber" component={waitFor(FormWithdrawal)} />

                                                  <Route exact path="/fixdeposit" component={waitFor(ListFixDepositData)} />
                                                  <Route exact path="/MobileUser" component={waitFor(MobileUser)} />
                                                  <Route exact path="/MobileUser/detail/:id" component={waitFor(MobileUserDetail)} />
                                                  <Route exact path={pathmenu.financingData} component={waitFor(LoanData)} />
                                                  <Route exact path="/member/saving-data-detail/:id"
                                                       component={waitFor(SavingDataDetail)} />
                                                  <Route exact path="/member/saving-data-detail/maintenance/:id"
                                                       component={waitFor(FormMaintenanceSavings)} />
                                                  <Route exact path="/member/fixdeposit-data-detail/:id"
                                                       component={waitFor(DepositDataDetail)} />
                                                  <Route exact path="/member/financing-data-detail/:id"
                                                       component={waitFor(LoanDetail)} />
                                                  <Route exact path="/approval/financing-approval"
                                                       component={waitFor(LoanNeedApproval)} />
                                                  <Route exact path="/approval/financing-disbursment"
                                                       component={waitFor(LoanNeedDisbursment)} />

                                                  <Route exact path="/biller/depositbiller"
                                                       component={waitFor(DepositBiller)} />
                                                  <Route exact path="/biller/settlementva"
                                                       component={waitFor(SettlementVA)} />
                                                  <Route exact path="/token/tokenservice"
                                                       component={waitFor(TokenService)} />

                                                  <Route exact path="/accounting/coa"
                                                       component={waitFor(CoaList)} />
                                                  <Route exact path="/accounting/coa/:id"
                                                       component={waitFor(CoaDetail)} />
                                                  <Route exact path="/accessmanagement/rolemanagement"
                                                       component={waitFor(RoleManagement)} />
                                                  <Route exact path="/accessmanagement/rolemanagement/detail/:id"
                                                       component={waitFor(DetailRoleManagement)} />
                                                  <Route exact path="/accounting/addcoa"
                                                       component={waitFor(AddFormCoa)} />
                                                  <Route exact path="/accounting/editcoa/:id"
                                                       component={waitFor(EditFormCoa)} />
                                                  <Route exact path="/accounting/addfrequentposting"
                                                       component={waitFor(AddFrequentPosting)} />

                                                  <Route exact path="/setting/accounting/accountingrules"
                                                       component={waitFor(accountingaccrulesList)} />
                                                  <Route exact path={pathmenu.addFormAccountingRules}
                                                       component={waitFor(addformaccrules)} />
                                                  <Route exact path={pathmenu.detailAccountingRules + '/:id'}
                                                       component={waitFor(detailaccrules)} />
                                                  <Route exact path={pathmenu.editFormAccountingRules + '/:id'}
                                                       component={waitFor(editformaccrules)} />
                                                  <Route exact path={pathmenu.formPrematureClosure + '/:id'}
                                                       component={waitFor(formPrematureClosure)} />

                                                  {/*<Route exact path="/member/loan-data-edit" component={waitFor(LoanDataEdit)}/>*/}
                                                  {/*<Route exact path="/member/loan-data-history"*/}
                                                  {/*component={waitFor(LoanDataHistory)}/>*/}
                                                  {/*<Route exact path="/member/loan-data-history-detail"*/}
                                                  {/*component={waitFor(LoanDataHistoryDetail)}/>*/}
                                                  {/*<Route exact path="/member/loan-data-history-edit"*/}
                                                  {/*component={waitFor(LoanDataHistoryEdit)}/>*/}
                                                  {/*<Route exact path="/member/loan-data-view" component={waitFor(LoanDataView)}/>*/}
                                                  {/*<Route exact path="/member/loan-data-view-detail"*/}
                                                  {/*component={waitFor(LoanDataViewDetail)}/>*/}
                                                  {/*<Route exact path="/member/loan-data-view-edit"*/}
                                                  {/*component={waitFor(LoanDataViewEdit)}/>*/}
                                                  {/*<Route exact path="/member/mobile-user" component={waitFor(MobileUser)}/>*/}
                                                  {/*<Route exact path="/member/mobile-user-detail"*/}
                                                  {/*component={waitFor(MobileUserDetail)}/>*/}
                                                  {/*component={waitFor(LoanDataViewEdit)}/>*/}
                                                  {/* <Route exact path="/member/mobile-user" component={waitFor(MobileUser)}/> */}

                                                  {/*<Route exact path="/member/mobile-user-edit"*/}
                                                  {/*component={waitFor(MobileUserEdit)}/>*/}
                                                  <Route exact path={pathmenu.printestatement + '/:accno'}
                                                       component={waitFor(printestatement)} />


                                                  <Route exact path={pathmenu.loanListMenu}
                                                       component={waitFor(listMenuLoan)} />
                                                  <Route exact path={pathmenu.organisation}
                                                       component={waitFor(listMenuOrganisation)} />
                                                  <Route exact path={pathmenu.listsubmenubillermanagement}
                                                       component={waitFor(listOrganisationBillerManagement)} />
                                                  <Route exact path={pathmenu.comingsoon}
                                                       component={waitFor(comingsoon)} />
                                                  <Route exact path={pathmenu.settingproducts}
                                                       component={waitFor(listMenuSettingProducts)} />
                                                  <Route exact path={pathmenu.settingaccounting}
                                                       component={waitFor(listMenuSettingAccounting)} />
                                                  <Route exact path={pathmenu.settingmobile}
                                                       component={waitFor(listMenuSettingMobile)} />
                                                  <Route exact path={pathmenu.settingmessaging}
                                                       component={waitFor(listMenuSettingMessaging)} />
                                                  <Route exact path={pathmenu.settinguser}
                                                       component={waitFor(listMenuSettingUserManagement)} />
                                                  <Route exact path={pathmenu.settingsecurity}
                                                       component={waitFor(listMenuSettingSecurityManagement)} />
                                                  <Route exact path={pathmenu.settingexternal}
                                                       component={waitFor(listMenuSettingExternalService)} />
                                                  <Route exact path={pathmenu.settingsystem}
                                                       component={waitFor(listMenuSettingSystem)} />



                                                  <Route exact path="/setting/securitymanagement"
                                                       component={waitFor(SecurityManagementIndex)} />
                                                  <Route exact path="/setting/securitymanagement/webmenumanagement"
                                                       component={waitFor(WebMenuManagementIndex)} />
                                                  <Route exact path="/setting/securitymanagement/webmenumanagement/formAddMenuParameter"
                                                       component={waitFor(AddWebMenuManagementForm)} />
                                                  <Route exact path="/setting/securitymanagement/webmenumanagement/:id"
                                                       component={waitFor(DetailWebMenuManagement)} />
                                                  <Route exact path="/setting/securitymanagement/webmenumanagement/formEditMenuParameter/:id"
                                                       component={waitFor(EditWebMenuManagement)} />
                                                  <Route exact path="/setting/securitymanagement/menupermissionparameter"
                                                       component={waitFor(WebMenuPermissionIndex)} />
                                                  <Route exact path="/setting/securitymanagement/menupermissionparameter/:id"
                                                       component={waitFor(DetailWebMenuPermission)} />
                                                  <Route exact path={pathmenu.unauthorized}
                                                       component={waitFor(unauthorized)} />
                                                  <Route exact path={pathmenu.addFormJournalEntries}
                                                       component={waitFor(AddJournalEntries)} />
                                                  <Route exact path={pathmenu.listPaylater}
                                                       component={waitFor(ListPaylater)} />
                                                  <Route exact path={pathmenu.settingMobileUserDetail + "/:id"} component={waitFor(MobileUserDetail)} />
                                                  <Route exact path={pathmenu.settingMobileUser} component={waitFor(MobileUser)} />
                                                  <Route exact path={pathmenu.settingInternalUser} component={waitFor(ListInternalUser)} />
                                                  <Route exact path={pathmenu.addFormInternalUser} component={waitFor(AddFormInternalUser)} />
                                                  <Route exact path={pathmenu.settingDetailInternalUser + "/:id"} component={waitFor(DetailInternalUser)} />
                                                  <Route exact path={pathmenu.editFormInternalUser + "/:id"} component={waitFor(EditFormInternalUser)} />
                                                  <Route exact path={pathmenu.chargesList} component={waitFor(ListCharges)} />
                                                  <Route exact path={pathmenu.detailCharges + "/:id"} component={waitFor(DetailCharges)} />
                                                  <Route exact path={pathmenu.AddCharges} component={waitFor(AddCharges)} />
                                                  <Route exact path={pathmenu.EditCharges + "/:id"} component={waitFor(EditCharges)} />
                                                  <Route exact path={pathmenu.reportMemberInstallmentList} component={waitFor(ReportsMonthlyInstalmentList)} />
                                                  <Route exact path={pathmenu.reportPaylaterList} component={waitFor(ReportsPaylater)} />
                                                  <Route exact path={pathmenu.reportMemberAccPaylaterSummary} component={waitFor(ReportsMemberAccountPaylaterSummary)} />
                                                  <Route exact path={pathmenu.reportMemberPaylaterSummary} component={waitFor(ReportsMemberPaylaterSummary)} />
                                                  {/* //EditFormInternalUser */}

                                                  <Route exact path={pathmenu.ListQrMerchant} component={waitFor(ListQrMerchant)} />
                                                  <Route exact path={pathmenu.DetailQrMerchant + "/:id"} component={waitFor(DetailQrMerchant)} />

                                                  <Route exact path={pathmenu.searchJournalEntries + "/:param"}
                                                       component={waitFor(SearchJournalEntries)} />
                                                  <Route exact path={pathmenu.searchJournalEntriesDetail + "/:param"}
                                                       component={waitFor(SearchJournalEntriesDetail)} />
                                                  <Route exact path={pathmenu.printAgreementLetter + '/:accno'}
                                                       component={waitFor(PrintAgreementLetter)} />
                                                  <Route exact path={pathmenu.printTransactionReceipt + '/:trxid'}
                                                       component={waitFor(PrintTransactionReceipt)} />

                                                  <Route exact path={pathmenu.createGlRecurring}
                                                       component={waitFor(CreateGLRecurring)} />
                                                  <Route exact path={pathmenu.sweepaccount}
                                                       component={waitFor(MenuSweepAccountList)} />
                                                  <Route exact path={pathmenu.autosweep + "/:tresholdacc"}
                                                       component={waitFor(MenuAutoSweep)} />
                                                  <Route exact path={pathmenu.autosweepdetail + "/:id"}
                                                       component={waitFor(AutoSweepDetail)} />
                                                  <Route exact path={pathmenu.addautosweep}
                                                       component={waitFor(AddAutoSweep)} />
                                                  <Route exact path={pathmenu.editautosweep + "/:id"}
                                                       component={waitFor(EditAutoSweep)} />
                                                  <Route exact path={pathmenu.autosweepgroup}
                                                       component={waitFor(MenuAutoSweepGroup)} />
                                                  <Route exact path={pathmenu.menuSettingSavings}
                                                       component={waitFor(MenuSettingsSavings)} />
                                                  <Route exact path={pathmenu.autosweepgroupdetail + "/:id"}
                                                       component={waitFor(MenuAutoSweepGroupDetail)} />
                                                  <Route exact path={pathmenu.addautosweepgroup}
                                                       component={waitFor(AddAutoSweepGroup)} />
                                                  <Route exact path={pathmenu.editautosweepgroup + "/:id"}
                                                       component={waitFor(EditAutoSweepGroup)} />
                                                  <Route exact path={pathmenu.autosweepbulkexception + "/:externalid"}
                                                       component={waitFor(MenuAutoSweepBulkException)} />
                                                  <Route exact path={pathmenu.autosweepbulk}
                                                       component={waitFor(MenuCreateAutoSweepBulk)} />
                                                  <Route exact path={pathmenu.updateautosweepbulk}
                                                       component={waitFor(MenuUpdateAutoSweepBulk)} />
                                                  <Route exact path={pathmenu.reportAutoSweep}
                                                       component={waitFor(ReportsAutoSweep)} />
                                                  <Route exact path={pathmenu.menublockedaccount}
                                                       component={waitFor(MenuBlockedAccount)} />
                                                  <Route exact path={pathmenu.addblockedaccount + "/:id"}
                                                       component={waitFor(AddBlockedAccount)} />
                                                  <Route exact path={pathmenu.detailblockedaccount + "/:id"}
                                                       component={waitFor(DetailBlockedAccount)} />
                                                  <Route exact path={pathmenu.editblockedaccount + "/:id"}
                                                       component={waitFor(EditBlockedAccount)} />

                                                  <Route exact path={pathmenu.menupromotionmanagement}
                                                       component={waitFor(MenuPromotionManagement)} />
                                                  <Route exact path={pathmenu.detailmenupromotionmanagement + "/:id"}
                                                       component={waitFor(DetailMenuPromotionManagement)} />
                                                  <Route exact path={pathmenu.addmenupromotionmanagement}
                                                       component={waitFor(AddMenuPromotionManagement)} />
                                                  <Route exact path={pathmenu.editmenupromotionmanagement + "/:id"}
                                                       component={waitFor(EditMenuPromotionManagement)} />

                                                  <Route exact path={pathmenu.memberEstatmenet + "/:id"}
                                                       component={waitFor(MemberEStatement)} />
                                                  <Route exact path={pathmenu.depositbillerservice}
                                                       component={waitFor(DepositBillerService)} />
                                                  <Route exact path={pathmenu.depositbillerservicedetail + "/:id"}
                                                       component={waitFor(DepositBillerServiceDetail)} />
                                                  <Route exact path={pathmenu.adddepositbillerservice}
                                                       component={waitFor(AddDepositBillerService)} />
                                                  <Route exact path={pathmenu.editdepositbillerservice + "/:id"}
                                                       component={waitFor(EditDepositBillerService)} />
                                                  <Route exact path={pathmenu.createdepositbiller}
                                                       component={waitFor(DepositBillerNew)} />
                                                  <Route exact path={pathmenu.adddepositbiller + "/:id"}
                                                       component={waitFor(AddDepositBillerNew)} />
                                                  <Route exact path={pathmenu.detaildepositbiller + "/:id"}
                                                       component={waitFor(detailDepositBillerNew)} />
                                                  <Route exact path={pathmenu.depositbillermitra}
                                                       component={waitFor(MenuDepositBillerMitra)} />

                                                  <Route exact path={pathmenu.detaildepositbillermitra + "/:id"}
                                                       component={waitFor(DetailDepositBillerMitra)} />
                                                  <Route exact path={pathmenu.adddepositbillermitra + "/:id"}
                                                       component={waitFor(AddDepositBillerMitra)} />


                                                  <Route exact path={pathmenu.prepayloan + "/:id"}
                                                       component={waitFor(prepayLoan)} />
                                                  <Route exact path={pathmenu.intermediary}
                                                       component={waitFor(MenuIntermediary)} />
                                                  <Route exact path={pathmenu.reportIntermediaryList} component={waitFor(ReportsIntermediary)} />
                                                  <Route exact path={pathmenu.reportMemberIntermediarySummary} component={waitFor(ReportsMemberIntermediarySummary)} />
                                                  <Route exact path={pathmenu.reportMemberIntermediaryAccSummary} component={waitFor(ReportsMemberIntermediaryAccSummary)} />
                                                  <Route exact path={pathmenu.submenuagencymanagement} component={waitFor(SubMenuAgencyManagement)} />
                                                  <Route exact path={pathmenu.menuagentgroup} component={waitFor(MenuAgentGroup)} />
                                                  <Route exact path={pathmenu.agentgroupdetail + "/:id"} component={waitFor(AgentGroupDetail)} />
                                                  <Route exact path={pathmenu.addagentgroup} component={waitFor(AddAgentGroup)} />
                                                  <Route exact path={pathmenu.editagentgroup + "/:id"} component={waitFor(EditAgentGroup)} />
                                                  <Route exact path={pathmenu.menuagent} component={waitFor(MenuAgent)} />
                                                  <Route exact path={pathmenu.detailmenuagent + "/:id"} component={waitFor(DetailMenuAgent)} />
                                                  <Route exact path={pathmenu.addmenuagent} component={waitFor(AddMenuAgent)} />
                                                  <Route exact path={pathmenu.editmenuagent + "/:id"} component={waitFor(EditMenuAgent)} />
                                                  <Route exact path={pathmenu.menucollectionsheet} component={waitFor(MenuLoanCollectionSheet)} />
                                                  <Route exact path={pathmenu.reportFinancingPaidOffDetails} component={waitFor(ReportFinancingPaidOffDetails)} />
                                                  <Route exact path={pathmenu.transferOverPaid + "/:id"} component={waitFor(TransferOverPaidLoan)} />

                                                  <Route exact path={pathmenu.requestfinancing + "/:id"} component={waitFor(FormRequestFinancing)} />
                                                  <Route exact path={pathmenu.menuOffices} component={waitFor(MenuOffices)} />
                                                  <Route exact path={pathmenu.detailOffices + "/:id"} component={waitFor(DetailOffices)} />
                                                  <Route exact path={pathmenu.addOffices} component={waitFor(AddOffices)} />
                                                  <Route exact path={pathmenu.editOffices + "/:id"} component={waitFor(EditOffices)} />
                                                  <Route exact path={pathmenu.MenuExternalService} component={waitFor(MenuExternalService)} />
                                                  <Route exact path={pathmenu.FormExternalServiceContactInfo} component={waitFor(FormExternalServiceContactInfo)} />
                                                  <Route exact path={pathmenu.menupassbooktype} component={waitFor(MenuPassbookType)} />
                                                  <Route exact path={pathmenu.addpassbooktype} component={waitFor(addPassbookType)} />
                                                  <Route exact path={pathmenu.detailpassbooktype + "/:id"} component={waitFor(detailPassbookType)} />
                                                  <Route exact path={pathmenu.editpassbooktype + "/:id"} component={waitFor(editPassbookType)} />
                                                  <Route exact path={pathmenu.printpassbookcover + "/:id"} component={waitFor(printPassbookCover)} />
                                                  <Route exact path={pathmenu.printpassbook + "/:id"} component={waitFor(printPassbook)} />
                                                  <Route exact path={pathmenu.reprintpassbook + "/:id"} component={waitFor(rePrintPassbook)} />
                                                  <Route exact path={pathmenu.menureferralcode} component={waitFor(menuReferralCode)} />
                                                  <Route exact path={pathmenu.addreferralcode} component={waitFor(addReferralCode)} />
                                                  <Route exact path={pathmenu.detailreferralcode + "/:id"} component={waitFor(detailReferralCode)} />
                                                  <Route exact path={pathmenu.editreferralcode + "/:id"} component={waitFor(editReferralCode)} />
                                                  <Route exact path={pathmenu.menuCreateSettlementIncoming} component={waitFor(MenuCreateSettlementIncoming)} />
                                                  <Route exact path={pathmenu.formCreateSettlementIncoming + "/:id"} component={waitFor(FormCreateSettlementIncoming)} />
                                                  <Route exact path={pathmenu.detailCreateSettlementIncoming + "/:id"} component={waitFor(DetailCreateSettlementIncoming)} />
                                                  <Route exact path={pathmenu.reportBiller} component={waitFor(reportsBiller)} />
                                                  <Route exact path={pathmenu.reporttransactionbillerbymonth} component={waitFor(reportsTransBillerByMonth)} />
                                                  <Route exact path={pathmenu.reporttransactionbillerbyyears} component={waitFor(reportsTransBillerByYears)} />
                                                  <Route exact path={pathmenu.reporttransactionbillerbytrxcode} component={waitFor(reportsTransBillerByTrxCode)} />
                                                  <Route exact path={pathmenu.formEmploymentInformation + "/:id"} component={waitFor(formEmploymentInformation)} />
                                                  <Route exact path={pathmenu.addformEmploymentInformation + "/:id"} component={waitFor(addformEmploymentInformation)} />
                                                  <Route exact path={pathmenu.formEmploymentInformation + "/:id/detail/:idclientjob"} component={waitFor(detailformEmploymentInformation)} />
                                                  <Route exact path={pathmenu.formEmploymentInformation + "/:id/edit/:idclientjob"} component={waitFor(editformEmploymentInformation)} />
                                                  <Route exact path={pathmenu.reportactivefinancingByDisrbusalPeriod} component={waitFor(reportsActiveFinancingByDisbursalPeriod)} />

                                                  <Route exact path={pathmenu.transactionMode}
                                                       component={waitFor(ListTransactionMode)} />
                                                  <Route exact path={pathmenu.addtransactionMode}
                                                       component={waitFor(AddTransactionMode)} />
                                                  <Route exact path={pathmenu.edittransactionMode + "/:id"}
                                                       component={waitFor(EditTransactionMode)} />
                                                  <Route exact path={pathmenu.transactionCode}
                                                       component={waitFor(ListTransactionCode)} />
                                                  <Route exact path={pathmenu.addtransactionCode}
                                                       component={waitFor(AddTransactionCode)} />
                                                  <Route exact path={pathmenu.detailtransactionCode + "/:id"}
                                                       component={waitFor(detailTransactionCode)} />
                                                  <Route exact path={pathmenu.edittransactionCode + "/:id"}
                                                       component={waitFor(EditTransactionCode)} />
                                                  <Route exact path={pathmenu.officeapproval + "/:id"}
                                                       component={waitFor(FormListApproval)} />
                                                  <Route exact path={pathmenu.formtransactionCodecontrol + "/:module/:externalid"}
                                                       component={waitFor(FormTransactionCodeControl)} />
                                                  <Route exact path={pathmenu.memberapproval}
                                                       component={waitFor(MemberApproval)} />
                                                  <Route exact path={pathmenu.memberapprovaldetail + "/:id"}
                                                       component={waitFor(MemberApprovalDetail)} />
                                                  <Route exact path={pathmenu.listmemberactivation}
                                                       component={waitFor(ListMemberActivation)} />
                                                  <Route exact path={pathmenu.formdepositpaylater + "/:id/:accountnumber"} component={waitFor(FormDepositPaylater)} />
                                                  <Route exact path={pathmenu.formwithdrawpaylater + "/:id/:accountnumber"} component={waitFor(FormWithdrawalPaylater)} />
                                                  <Route exact path={pathmenu.reportMemberListing} component={waitFor(reportsMemberListingSimple)} />
                                                  <Route exact path={pathmenu.reportEmployeeListing} component={waitFor(reportEmployeeListingSimple)} />
                                                  <Route exact path={pathmenu.reportMemberPaylaterBalance} component={waitFor(reportMemberPaylaterBalance)} />
                                                  <Route exact path={pathmenu.menuFormUpdateSpecialRate + "/:id"} component={waitFor(MenuUpdateSpecialRate)} />
                                                  <Route exact path={pathmenu.menuFormResetSpecialRate + "/:id"} component={waitFor(MenuResetSpecialRate)} />

                                                  <Route exact path={pathmenu.menuSavingsProduct} component={waitFor(menuSavingsProduct)} />
                                                  <Route exact path={pathmenu.AddSavingsProduct} component={waitFor(AddSavingsProduct)} />
                                                  <Route exact path={pathmenu.DetailSavingsProduct + "/:id"} component={waitFor(DetailSavingsProduct)} />
                                                  <Route exact path={pathmenu.EditSavingsProduct + "/:id"} component={waitFor(EditSavingsProduct)} />
                                                  <Route exact path={pathmenu.menuUnitData} component={waitFor(menuUnitData)} />
                                                  <Route exact path={pathmenu.menuAddUnitData} component={waitFor(menuAddUnitData)} />
                                                  <Route exact path={pathmenu.menuEditUnitData + "/:id"} component={waitFor(menuEditUnitData)} />
                                                  <Route exact path={pathmenu.menuUnitUser} component={waitFor(menuUnitUser)} />
                                                  <Route exact path={pathmenu.menuAddUnitUser} component={waitFor(menuAddUnitUser)} />
                                                  <Route exact path={pathmenu.menuDetailUnitUser + "/:id"} component={waitFor(menuDetailUnitUser)} />
                                                  <Route exact path={pathmenu.menuEditUnitUser + "/:id"} component={waitFor(menuEditUnitUser)} />
                                                  <Route exact path={pathmenu.menuUnitDataDetail + "/:id"} component={waitFor(detailUnitData)} />
                                                  <Route exact path={pathmenu.reportOpeningPaylater} component={waitFor(reportOpeningPaylater)} />
                                                  <Route exact path={pathmenu.PaylaterDisbursementSummary} component={waitFor(reportPaylaterDisbursementSummary)} />
                                                  <Route exact path={pathmenu.PaylaterDisbursement} component={waitFor(reportPaylaterDisbursement)} />

                                                  <Route exact path={pathmenu.transactionKoperasiIn} component={waitFor(reportsTransIn)} />
                                                  <Route exact path={pathmenu.transactionKoperasiOut} component={waitFor(reportsTransOut)} />
                                                  <Route exact path={pathmenu.bulktransaction} component={waitFor(menuBulkTransaction)} />
                                                  <Route exact path={pathmenu.transactionCommerces} component={waitFor(reportTransactionCommerces)} />
                                                  <Route exact path={pathmenu.transactionKoperasi} component={waitFor(reportTransactionAntarKoperasi)} />
                                                  <Route exact path={pathmenu.reconciliationBiller} component={waitFor(reportReconciliationBiller)} />
                                                  <Route exact path={pathmenu.mobileUserNotLogin} component={waitFor(mobileUserNotLogin)} />
                                                  <Route exact path={pathmenu.reportRingkasanPengirimanPesan} component={waitFor(ReportRingkasanPengirimanPesan)} />

                                                  <Route exact path={pathmenu.reportActivationMember} component={waitFor(reportMemberTransactionActivity)} />
                                                  <Route exact path={pathmenu.passbookmanagement} component={waitFor(menuPassBookManagement)} />
                                                  <Route exact path={pathmenu.savingpassbookmanagement} component={waitFor(menuSavingPassBookManagement)} />
                                                  <Route exact path={pathmenu.financingpassbookmanagement} component={waitFor(menuFinancingPassBookManagement)} />
                                                  <Route exact path={pathmenu.financingpassbook} component={waitFor(menuFinancingPassBook)} />
                                                  <Route exact path={pathmenu.addfinacingpassbooktype} component={waitFor(addFinancingPassBookType)} />
                                                  <Route exact path={pathmenu.detailfinacingpassbooktype + "/:id"} component={waitFor(detailFinancingPassBookType)} />
                                                  <Route exact path={pathmenu.editfinacingpassbooktype + "/:id"} component={waitFor(editFormFinancingPassbookType)} />
                                                  <Route exact path={pathmenu.savingpassbookCover} component={waitFor(savingPassbookCover)} />
                                                  <Route exact path={pathmenu.resetpassbook} component={waitFor(resetSavingPassbook)} />
                                                  <Route exact path={pathmenu.reprintsavingpassbook} component={waitFor(rePrintSavingPassbook)} />
                                                  <Route exact path={pathmenu.printsavingpassbook} component={waitFor(printSavingPassbook)} />
                                                  <Route exact path={pathmenu.financingpassbookcover} component={waitFor(financingPassBookCover)} />
                                                  <Route exact path={pathmenu.printfinancingpassbook} component={waitFor(printFinancingPassBook)} />
                                                  <Route exact path={pathmenu.resetfinancingpassbook} component={waitFor(resetFinancingPassbook)} />
                                                  <Route exact path={pathmenu.rePrintfinancingpassbook} component={waitFor(rePrintFinancingPassbook)} />

                                                  <Route exact path={pathmenu.financingpassbookmanagementLoan} component={waitFor(listMenuPassbookLoan)} />
                                                  <Route exact path={pathmenu.transactionLimit} component={waitFor(menuTransLimit)} />
                                                  <Route exact path={pathmenu.addtransactionLimit} component={waitFor(addTelllerLimit)} />
                                                  <Route exact path={pathmenu.edittransactionLimit + "/:id"} component={waitFor(editTellerLimit)} />
                                                  <Route exact path={pathmenu.tellermanagement} component={waitFor(menuTellerManagement)} />
                                                  <Route exact path={pathmenu.detailtransactionLimit + "/:id"} component={waitFor(detailTelllerLimit)} />
                                                  <Route exact path={pathmenu.cashiertransaction} component={waitFor(menuTransactionCashier)} />
                                                  <Route exact path={pathmenu.cashdeposit} component={waitFor(menuCashDeposit)} />
                                                  <Route exact path={pathmenu.cashwithdrawal} component={waitFor(menuCashWithdrawal)} />
                                                  <Route exact path={pathmenu.employmentlevel} component={waitFor(employmentLevel)} />
                                                  <Route exact path={pathmenu.addemploymentlevel} component={waitFor(addEmployementLevel)} />
                                                  <Route exact path={pathmenu.detailemploymentlevel + "/:id"} component={waitFor(detailEmployementLevel)} />
                                                  <Route exact path={pathmenu.editemploymentlevel + "/:id"} component={waitFor(editEmployementLevel)} />
                                                  <Route exact path={pathmenu.cashgeneraltransaction} component={waitFor(menuCashGeneralTransaction)} />
                                                  <Route exact path={pathmenu.telleraccount} component={waitFor(menuTellerAccount)} />
                                                  <Route exact path={pathmenu.addtelleraccount} component={waitFor(addTellerAccount)} />
                                                  <Route exact path={pathmenu.detailtelleraccount + "/:id"} component={waitFor(detailTellerAccount)} />
                                                  <Route exact path={pathmenu.edittelleraccount + "/:id"} component={waitFor(editTellerAccount)} />
                                                  <Route exact path={pathmenu.allocatecash + "/:id"} component={waitFor(allocateCash)} />
                                                  <Route exact path={pathmenu.settlementcash} component={waitFor(settleCash)} />
                                                  <Route exact path={pathmenu.denomination} component={waitFor(menuDenomination)} />
                                                  <Route exact path={pathmenu.adddenomination} component={waitFor(addDenomination)} />
                                                  <Route exact path={pathmenu.detaildenomination + "/:id"} component={waitFor(detailDenomination)} />
                                                  <Route exact path={pathmenu.editdenomination + "/:id"} component={waitFor(editDenomination)} />
                                                  <Route exact path={pathmenu.reportTeller} component={waitFor(reportTeller)} />
                                                  <Route exact path={pathmenu.reportcashallocation} component={waitFor(reportCashAllocation)} />
                                                  <Route exact path={pathmenu.reportcashsettlement} component={waitFor(reportCashSettlement)} />
                                                  <Route exact path={pathmenu.reportdailycash} component={waitFor(reportDailyCash)} />
                                                  <Route exact path={pathmenu.overbookingteller} component={waitFor(menuOverbookingTeller)} />
                                                  <Route exact path={pathmenu.menusavingpassbook} component={waitFor(menuSavingPassbookTeller)} />
                                                  <Route exact path={pathmenu.transactiontellerapproval} component={waitFor(menuTransactionTellerApproval)} />
                                                  <Route exact path={pathmenu.detailtransactiontellerapproval + "/:id"} component={waitFor(detailTransactionTellerApproval)} />
                                                  <Route exact path={pathmenu.menucashier} component={waitFor(menuCashier)} />
                                                  <Route exact path={pathmenu.detailtelleroperation + "/:id"} component={waitFor(detailTellerOperation)} />
                                                  <Route exact path={pathmenu.addtelleroperation} component={waitFor(addTellerOperation)} />
                                                  <Route exact path={pathmenu.edittelleroperation + "/:id"} component={waitFor(editTellerOperation)} />
                                                  <Route exact path={pathmenu.settleteller} component={waitFor(settleCashTeller)} />
                                                  <Route exact path={pathmenu.allocateTeller + "/:id"} component={waitFor(allocateCashTeller)} />
                                                  <Route exact path={pathmenu.cashirgeneraltransaction} component={waitFor(cashierGeneralTransaction)} />
                                                  <Route exact path={pathmenu.menuParamReportGLSummary} component={waitFor(menuReportParamGLSummaryTabelaris)} />
                                                  <Route exact path={pathmenu.addParamReportGLSummary + "/:id"} component={waitFor(addReportParamGLSummaryTabelaris)} />
                                                  <Route exact path={pathmenu.detailParamReportGLSummary + "/:id"} component={waitFor(detailReportParamGLSummaryTabelaris)} />
                                                  <Route exact path={pathmenu.editParamReportGLSummary + "/:id"} component={waitFor(editReportParamGLSummaryTabelaris)} />
                                                  <Route exact path={pathmenu.reportGLSummary} component={waitFor(reportParamGLSummaryTabelaris)} />
                                                  <Route exact path={pathmenu.reprintreceipt} component={waitFor(menuRePrintReceipt)} />
                                                  <Route exact path={pathmenu.menugeneraltransaction} component={waitFor(menuGeneralTransaction)} />
                                                  <Route exact path={pathmenu.financingsubmission} component={waitFor(menuLoanSubmission)} />
                                                  <Route exact path={pathmenu.reportsavingaccountopened} component={waitFor(reportSavingsAccountOpened)} />
                                                  <Route exact path={pathmenu.reportsavingaccountclosed} component={waitFor(reportSavingsAccountClosed)} />
                                                  <Route exact path={pathmenu.PeriodicReport} component={waitFor(menuReportJob)} />
                                                  <Route exact path={pathmenu.DetailPeriodicReport + "/:id"} component={waitFor(detailReportJob)} />
                                                  <Route exact path={pathmenu.ViewCsv} component={waitFor(viewCSV)} />
                                                  <Route exact path={pathmenu.menuProductFinancing} component={waitFor(menuProductFinancing)} />
                                                  <Route exact path={pathmenu.addProductFinancing} component={waitFor(addFinancingProduct)} />
                                                  <Route exact path={pathmenu.detailProductFinancing + "/:id"} component={waitFor(detailFinancingProduct)} />
                                                  <Route exact path={pathmenu.editProductFinancing + "/:id"} component={waitFor(editlFinancingProduct)} />
                                                  <Route exact path={pathmenu.ReportMonthlyTermSavingMarginReport} component={waitFor(ReportMonthlyFixedDepositInterestReportRanged)} />
                                                  <Route exact path={pathmenu.ReportTermSavingAccrueWillBePaid} component={waitFor(reportTermSavingAccrueWillBePaid)} />
                                                  <Route exact path={pathmenu.ReportTermSavingMarginDueReport} component={waitFor(reportTermSavingMarginDueReport)} />
                                                  <Route exact path={pathmenu.ReportTermSavingWillBeMaturedReport} component={waitFor(ReportTermSavingWillBeMaturedReport)} />
                                                  <Route exact path={pathmenu.ReportTermSavingClosed} component={waitFor(ReportTermSavingClosed)} />
                                                  <Route exact path={pathmenu.ReportTermSavingMaintenance} component={waitFor(ReportTermSavingMaintenance)} />
                                                  <Route exact path={pathmenu.ReportTotalTermSavingAccount} component={waitFor(ReportTotalTermSavingAccount)} />
                                                  <Route exact path={pathmenu.menuRecurring} component={waitFor(menuRecurring)} />
                                                  <Route exact path={pathmenu.detailRecurring + "/:id"} component={waitFor(detailRecurring)} />
                                                  <Route exact path={pathmenu.closeFormRecurring + "/:id"} component={waitFor(closeFormRecurring)} />
                                                  <Route exact path={pathmenu.depositRecurring + "/:idclient/:accnorecurr"} component={waitFor(depositRecurring)} />
                                                  <Route exact path={pathmenu.CashierDepositRecurring} component={waitFor(cashierDepositRecurring)} />
                                                  <Route exact path={pathmenu.reportGLSummaryDaily} component={waitFor(reportParamGLSummaryTabelarisDaily)} />

                                                  <Route exact path={pathmenu.reportRecurring} component={waitFor(ReportRecurring)} />
                                                  <Route exact path={pathmenu.reportRecurringActive} component={waitFor(ReportRecurringActive)} />
                                                  <Route exact path={pathmenu.reportRecurringDueDate} component={waitFor(ReportRecurringDueDate)} />
                                                  <Route exact path={pathmenu.reportRecurringElapsed} component={waitFor(ReportRecurringElapsed)} />
                                                  <Route exact path={pathmenu.reportRecurringCairTutup} component={waitFor(ReportRecurringCairTutup)} />
                                                  <Route exact path={pathmenu.reportSavingPlanDepositDue} component={waitFor(ReportSetoranJatuhTempo)} />
                                                  <Route exact path={pathmenu.reportSavingPlanList} component={waitFor(ReportDaftarTabunganBerjangka)} />
                                                  <Route exact path={pathmenu.addMerchantList} component={waitFor(addMerchantList)} />
                                                  <Route exact path={pathmenu.menuMerchant} component={waitFor(merchantMenus)} />
                                                  <Route exact path={pathmenu.detailMerchantList + "/:id"} component={waitFor(detailMerchantList)} />
                                                  <Route exact path={pathmenu.merchantApproval} component={waitFor(merchantApproval)} />
                                                  <Route exact path={pathmenu.merchantApprovalDetail + "/:id"} component={waitFor(merchantApprovalDetail)} />
                                                  <Route exact path={pathmenu.editmerchantApproval + "/:id"} component={waitFor(editMerchant)} />
                                                  {/* <Route exact path={pathmenu.merchantReSubmit} component={waitFor(merchantReSubmit)}/> */}
                                                  <Route exact path={pathmenu.merchantReSubmitForm + "/:id"} component={waitFor(merchantReSubmitForm)} />
                                                  <Route exact path={pathmenu.ReportRejectedFinancingRequest} component={waitFor(ReportRejectedFinancingRequest)} />
                                                  <Route exact path={pathmenu.ReportTax} component={waitFor(ReportTax)} />
                                                  <Route exact path={pathmenu.ReportTaxSummary} component={waitFor(ReportTaxSummary)} />
                                                  <Route exact path={pathmenu.editTokenService} component={waitFor(EditTokenService)} />


                                                  <Route exact path={pathmenu.editRecurring + "/:id"} component={waitFor(editRecurring)} />

                                                  <Route exact path={pathmenu.menuSettingToken} component={waitFor(MenuSettingToken)} />
                                                  <Route exact path={pathmenu.menuSettingTokenService} component={waitFor(MenuSettingTokenService)} />
                                                  <Route exact path={pathmenu.addchargesSavings + "/:id"} component={waitFor(addChargesSaving)} />
                                                  <Route exact path={pathmenu.accountingJournal} component={waitFor(accountingJournal)}/>
                                                  <Route exact path={pathmenu.interBranchSettlement} component={waitFor(interBranchSettlement)}/>
                                                  <Route exact path={pathmenu.interBranchSettlementRequest} component={waitFor(InterBranchSettlementRequest)}/>
                                                  <Route exact path={pathmenu.addinterBranchSettlementRequest} component={waitFor(addInterBranchSettlementRequest)}/>
                                                  <Route exact path={pathmenu.interBranchSettlementApprove} component={waitFor(InterBranchSettlementApprove)}/>
                                                  <Route exact path={pathmenu.editInterBranchSettlementApprove+"/:id"} component={waitFor(editInterBranchSettlementApprove)}/>
                                                  <Route exact path={pathmenu.interBranchSettlementConfirm} component={waitFor(InterBranchSettlementConfirm)}/>
                                                  <Route exact path={pathmenu.laporanPermintaanPelimpahanAntarCabang} component={waitFor(laporanPermintaanPelimpahanAntarCabang)}/>
                                                  <Route exact path={pathmenu.laporanPersetujuanPelimpahanAntarCabang} component={waitFor(laporanPersetujuanPelimpahanAntarCabang)}/>
                                                  <Route exact path={pathmenu.laporanPengirimanPelimpahanAntarCabang} component={waitFor(laporanPengirimanPelimpahanAntarCabang)}/>
                                                  <Route exact path={pathmenu.laporanRekapPelimpahanAntarCabang} component={waitFor(laporanRekapPelimpahanAntarCabang)}/>
                                                  <Route exact path={pathmenu.mobilemanagement} component={waitFor(menuMobileManagement)}/>
                                                  <Route exact path={pathmenu.mobilemenu} component={waitFor(mobileMenu)}/>
                                                  <Route exact path={pathmenu.addmobilemenu} component={waitFor(addmobileMenu)}/>
                                                  <Route exact path={pathmenu.detailmobilemenu+"/:id"} component={waitFor(detailmobileMenu)}/>
                                                  <Route exact path={pathmenu.editmobilemenu+"/:id"} component={waitFor(editmobileMenu)}/>
                                                  <Route exact path={pathmenu.mobilemenugroup} component={waitFor(mobileMenuGroup)}/>
                                                  <Route exact path={pathmenu.addmobilemenugroup} component={waitFor(addmobileMenuGroup)}/>
                                                  <Route exact path={pathmenu.detailmobilemenugroup+"/:id"} component={waitFor(detailmobileMenuGroup)}/>
                                                  <Route exact path={pathmenu.editmobilemenugroup+"/:id"} component={waitFor(editmobileMenuGroup)}/>
                                                  <Route exact path={pathmenu.mobilemenumitra} component={waitFor(mobileMenuMitra)}/>
                                                  <Route exact path={pathmenu.addmobilemenumitra} component={waitFor(addmobileMenuMitra)}/>
                                                  <Route exact path={pathmenu.detailmobilemenumitra+"/:id"} component={waitFor(detailmobileMenuMitra)}/>
                                                  <Route exact path={pathmenu.editmobilemenumitra+"/:id"} component={waitFor(editmobileMenuMitra)}/>
                                                  <Route exact path={pathmenu.detailmobilethememenumitra+"/:id"} component={waitFor(detailmobileThemeMenuMitra)}/>
                                                  <Route exact path={pathmenu.addmobilethememenumitra+"/:id"} component={waitFor(addmobileThemeMenuMitra)}/>
                                                  <Route exact path={pathmenu.editmobilethememenumitra+"/:id"} component={waitFor(editMobileTheme)}/>
                                                  <Route exact path={pathmenu.mobileversion} component={waitFor(menuMobileVersion)}/>
                                                  <Route exact path={pathmenu.mobilecommerce} component={waitFor(menuMobileCommerce)}/>
                                                  <Route exact path={pathmenu.addmobilecommerce} component={waitFor(addMobileCommerce)}/>
                                                  <Route exact path={pathmenu.detailmobilecommerce+"/:id"} component={waitFor(detailMobileCommerce)}/>
                                                  <Route exact path={pathmenu.editmobilecommerce+"/:id"} component={waitFor(editMobileCommerce)}/>
                                                  
                                                  <Route exact path={pathmenu.producttemplate} component={waitFor(ProductTemplate)}/>
                                                  <Route exact path={pathmenu.detailproducttemplate+"/:id/:idprodtype"} component={waitFor(DetailProductTemplate)}/>
                                                  <Route exact path={pathmenu.addproducttemplate} component={waitFor(addProductTemplate)}/>
                                                  <Route exact path={pathmenu.editproducttemplate+"/:id/:idprodtype"} component={waitFor(editFormProductTemplate)}/>
                                                  <Route exact path={pathmenu.ReportTaxMember} component={waitFor(ReportTaxMember)} />
                                                  <Route exact path={pathmenu.menuJobs} component={waitFor(menuJobs)}/>
                                                  <Route exact path={pathmenu.detailJobs+"/:id"} component={waitFor(detailJobs)}/>
                                                  <Route exact path={pathmenu.historyJobs+"/:id"} component={waitFor(historyJobs)}/>
                                                  <Route exact path={pathmenu.SearchGLRecurring} component={waitFor(searchRecurringJournal)}/>
                                                  <Route exact path={pathmenu.detailSearchGLRecurring+"/:id"} component={waitFor(detailSearchRecurringJournal)}/>
                                                  <Route exact path={pathmenu.reportSearchGLRecurring+"/:id"} component={waitFor(reportGLRecurringJournal)}/>

                                                  <Route exact path={pathmenu.financingApprovalDetails+"/:id"} component={waitFor(financingApprovalDetails)}/>
                                                  <Route exact path={pathmenu.financingApprovalDetailsApprove+"/:id"} component={waitFor(financingApprovalApprove)}/>
                                                  <Route exact path={pathmenu.financingApprovalDetailsReject+"/:id"} component={waitFor(financingApprovalRejected)}/>
                                                  <Route exact path={pathmenu.financingModifyApproval+"/:id"} component={waitFor(modifyFinancingApproval)}/>
                                                  
                                                  
                                                  <Route exact path={pathmenu.menuApprovalManagement} component={waitFor(menuApprovalManagement)}/>
                                                  <Route exact path={pathmenu.ClientApprovalList} component={waitFor(menuClientApprovalList)}/>
                                                  <Route exact path={pathmenu.LoanApprovalList} component={waitFor(menuLoanApprovalList)}/>
                                                  <Route exact path={pathmenu.userGroup} component={waitFor(menuUserGroup)}/>
                                                  <Route exact path={pathmenu.addUserGroup} component={waitFor(addUserGroup)}/>
                                                  <Route exact path={pathmenu.detailUserGroup+"/:id"} component={waitFor(detailUserGroup)}/>
                                                  <Route exact path={pathmenu.editUserGroup+"/:id"} component={waitFor(editUserGroup)}/>
                                                  <Route exact path={pathmenu.reportLoanRepayment} component={waitFor(reportLoanRepayment)} />
                                                  

                                                  <Route exact path={pathmenu.transferMember+"/:id"} component={waitFor(MenuTransferMember)}/>
                                                  <Route exact path={pathmenu.approveTransferMember+"/:id"} component={waitFor(approveTransferMember)}/>
                                                  <Route exact path={pathmenu.rejectTransferMember+"/:id"} component={waitFor(rejectTransferMember)}/>
                                                  <Route exact path={pathmenu.memberApproveRejectTransfer} component={waitFor(memberApproveRejectTransfer)}/>
                                                  <Route exact path={pathmenu.overbookingIntermediary+"/:id"} component={waitFor(overbookingForm)}/>

                                                  <Route exact path={pathmenu.menuproductgroup} component={waitFor(menuProductGroup)}/>
                                                  <Route exact path={pathmenu.addproductgroup} component={waitFor(addProductGroup)}/>
                                                  <Route exact path={pathmenu.detailproductgroup+"/:id"} component={waitFor(detailProductGroup)}/>
                                                  <Route exact path={pathmenu.editproductgroup+"/:id"} component={waitFor(editProductGroup)}/>
                                                  <Route exact path={pathmenu.coopProfile} component={waitFor(coopProfile)}/>
                                                  <Route exact path={pathmenu.menuPosition} component={waitFor(menuOrganisationPosition)}/>
                                                  <Route exact path={pathmenu.addPosition} component={waitFor(addOrganisationPosition)}/>
                                                  <Route exact path={pathmenu.detailPosition+"/:id"} component={waitFor(detailOrganisationPosition)}/>
                                                  <Route exact path={pathmenu.editPosition+"/:id"} component={waitFor(editOrganisationPosition)}/>
                                                  <Route exact path={pathmenu.menuStructure} component={waitFor(menuOrganisationStructure)}/>
                                                  <Route exact path={pathmenu.addStructure} component={waitFor(addOrganisationStructure)}/>
                                                  <Route exact path={pathmenu.detailStructure+"/:id"} component={waitFor(detailOrganisationStructure)}/>
                                                  <Route exact path={pathmenu.editStructure+"/:id"} component={waitFor(editOrganisationStructure)}/>
                                                  <Route exact path={pathmenu.printLoanAgreementQrCode+"/:id/:loanaccnumber"} component={waitFor(printLoanAgreementQrCode)}/>
                                                  
                                                  

                                                  <Route exact path={pathmenu.provosioningcriteria} component={waitFor(LoanProvisioningParameter)}/>
                                                  <Route exact path={pathmenu.addprovosioningcriteria} component={waitFor(AddLoanProvisioningParameter)}/>
                                                  <Route exact path={pathmenu.detailprovosioningcriteria+"/:id"} component={waitFor(DetailLoanProvisioningParameter)}/>
                                                  <Route exact path={pathmenu.editprovosioningcriteria+"/:id"} component={waitFor(EditLoanProvisioningParameter)}/>

                                                  <Route exact path={pathmenu.settingfinancing} component={waitFor(MenuSettingFinancing)}/>
                                                  <Route exact path={pathmenu.reportRincianAktivitasTransaksiAnggota} component={waitFor(reportRincianAktivitasTransaksiAnggota)}/>
                                                  <Route exact path={pathmenu.transferUnit+"/:id"} component={waitFor(transferUnit)}/>
                                                  <Route exact path={pathmenu.approveTransferUnit+"/:id"} component={waitFor(approveTransferUnit)}/>
                                                  <Route exact path={pathmenu.rejectTransferUnit+"/:id"} component={waitFor(rejectTransferUnit)}/>
                                                  <Route exact path={pathmenu.detailintermediary+"/:id"} component={waitFor(detailIntermediary)}/>
                                                  <Route exact path={pathmenu.savingtoloan} component={waitFor(savingToLoan)}/>
                                                  
                                                  
                                                  
                                                  <Route exact path={pathmenu.collectionAgent} component={waitFor(collectionAgent)} />
                                                  <Route exact path={pathmenu.printPaymentDetail+"/:id"} component={waitFor(printpaymentDetails)}/>
                                                  <Route exact path={pathmenu.printRepaymentSchedule+"/:id"} component={waitFor(printrepaymentSchedule)}/>
                                                  <Route exact path={pathmenu.detailcollectionsheet+"/:id"} component={waitFor(detailsCollectionSheet)}/>
                                                  <Route exact path={pathmenu.collectionAgentDetail+"/:id"} component={waitFor(collectionAgentDetail)} />
                                                  <Route exact path={pathmenu.specialToken} component={waitFor(menuSpecialToken)}/>
                                                  <Route exact path={pathmenu.requestToken} component={waitFor(requestToken)}/>
                                                  <Route exact path={pathmenu.detailLoanDisbursment+"/:id"} component={waitFor(detailLoanDisbursement)} />
                                                  <Route exact path={pathmenu.detailTokenTopUp+"/:id"} component={waitFor(detailTokenTopUp)} />
                                                  <Route exact path={pathmenu.reportsavingstransaction} component={waitFor(reportSavingsTransaction)}/>
                                                  <Route exact path={pathmenu.bulktransactionHistory} component={waitFor(listHistoryBulk)}/>
                                                  <Route exact path={pathmenu.baseListreportDataImport} component={waitFor(listReportDataImport)}/>
                                                  <Route exact path={pathmenu.reportDataImport} component={waitFor(reportDataImport)}/>
                                                  <Route exact path={pathmenu.bulktransactionHistory+"/:id"} component={waitFor(historyBulkIdParam)}/>
                                                  <Route exact path={pathmenu.reportagent} component={waitFor(ReportsAgentList)}/>
                                                  <Route exact path={pathmenu.agentTransactionReport} component={waitFor(transactionAgentReport)}/>
                                                  <Route exact path={pathmenu.agentComissionReport} component={waitFor(agentComissionReport)}/>
                                                  <Route exact path={pathmenu.formatreports} component={waitFor(reportFormatReports)}/>
                                                  <Route exact path={pathmenu.financingapprovalsingle} component={waitFor(loanApprovalSingle)}/>
                                                  <Route exact path={pathmenu.reportsavingsstatus} component={waitFor(reportSavingsAccountStatus)}/>
                                                  <Route exact path={pathmenu.monitoring} component={waitFor(tokenMonitoring)}/>
                                                  <Route exact path={pathmenu.baganakunbukubesar} component={waitFor(reportBaganAkunBukuBesar)}/>
                                                  
                                                  <Route exact path="/logout" component={waitFor(Logout)} />
                                                  <Route exact path="/login" component={waitFor(Login)} />
                                                  {/* Dashboard */}
                                                  <Route exact path={["/dashboard"]} component={waitFor(Dashboard)} />
                                                  <Route exact path={["/home", "/"]} component={waitFor(Home)} />
                                                  <Redirect to="/notfound" />
                                             </Switch>
                                        </Suspense>
                                   </div>
                              </CSSTransition>
                         </TransitionGroup>
                    </Base>
               )
          }
     } else {
          return (
               <BasePage>
                    <Suspense fallback={<PageLoader />}>
                         <Switch>
                              <Route path={'/notfound'} component={waitFor(NotFound)} />
                              <Route exact path={["/login", "/"]} component={waitFor(Login)} />
                              <Redirect from='*' to="/login" />
                         </Switch>
                    </Suspense>
               </BasePage>
          );
     }
};


export default withRouter(withTranslation('translations')(Routes));
