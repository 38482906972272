import { type } from 'jquery';
import * as actions from './actionTypes';

export const GetListGLAccount = (successHandler, errorHandler) => {
    return {
        type: actions.GET_LIST_GL_ACCOUNT,
        successHandler,
        errorHandler,
    }
};

export const setListCOA = (data) => {
    return {
        type: actions.FETCH_LIST_COA,
        data: data,
    };
};

export const GetGLAccountByID = (id,successHandler, errorHandler) => {
    return {
        type: actions.GET_COA_BY_ID,
        id:id,
        successHandler,
        errorHandler,
    }
};

export const GetGLTemplate = (successHandler, errorHandler) => {
    return {
        type: actions.GET_GL_TEMPLATE,
        successHandler,
        errorHandler,
    }
};

export const GetGLTemplateWithType = (typeid,successHandler, errorHandler) => {
    return {
        type: actions.GET_GL_TEMPLATE_WITH_TYPE,
        typeid:typeid,
        successHandler,
        errorHandler,
    }
};
export const GetAccountingRules = (successHandler, errorHandler) => {
    return {
        type: actions.GET_ACCOUNTING_RULES,
        successHandler,
        errorHandler,
    }
};

export const submitGLAccount = (payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_GL_ACCOUNT,
        payload:payload,
        successHandler,
        errorHandler
    }
};

export const getGlAccountByID = (id,successHandler, errorHandler) => {
    return {
        type: actions.GET_GL_ACCOUNT_BY_ID,
        id:id,
        successHandler,
        errorHandler
    }
};
export const GetPaymentTypes = (successHandler, errorHandler) => {
    return {
        type: actions.GET_PAYMENTTYPES,
        successHandler,
        errorHandler,
    }
};

export const submitjurnalentries = (payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_JURNAL_ENTRIES,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const GetListAccountRules = (successHandler, errorHandler) => {
    return {
        type: actions.GET_LIST_ACCOUNT_RULES,
        successHandler,
        errorHandler,
    }
};

export const GetAccountRulesTemplate = (successHandler, errorHandler) => {
    return {
        type: actions.GET_ACCOUNT_RULES_TEMPLATE,
        successHandler,
        errorHandler,
    }
};

export const submitaccountingrules = (payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_ACCOUNTING_RULES,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const getdetailaccountingrules = (id,successHandler, errorHandler) => {
    return {
        type: actions.GET_ACCOUNTING_RULES_DETAIL,
        id:id,
        successHandler,
        errorHandler,
    }
};

export const updateGLAccount = (id,payload,successHandler, errorHandler) => {
    return {
        type: actions.UPDATE_GL_ACCOUNT,
        id:id,
        payload:payload,
        successHandler,
        errorHandler
    }
};

export const deleteGLAccount = (id,successHandler, errorHandler) => {
    return {
        type: actions.DELETE_GL_ACCOUNT,
        id:id,
        successHandler,
        errorHandler
    }
};
export const deleteaccountingrules = (id,successHandler, errorHandler) => {
    return {
        type: actions.DELETE_ACCOUNTING_RULES,
        id:id,
        successHandler,
        errorHandler,
    }
};

export const getdetailaccountingruleswithtemplate = (id,successHandler, errorHandler) => {
    return {
        type: actions.GET_ACCOUNTING_RULES_DETAIL_WITH_TEMPLATE,
        id:id,
        successHandler,
        errorHandler,
    }
};

export const updateaccountingrules = (id,payload,successHandler, errorHandler) => {
    return {
        type: actions.UPDATE_ACCOUNTING_RULES,
        id:id,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const getSearchJournalEntriesByParam = (queryparam,successHandler, errorHandler) => {
    return {
        type: actions.SEARCH_JOURNAL_ENTRIES_BY_PARAM,
        queryparam:queryparam,
        successHandler,
        errorHandler,
    }
};

export const GetRecurringTemplate = (successHandler, errorHandler) => {
    return {
        type: actions.GET_GLRECURRING_TEMPLATE,
        successHandler,
        errorHandler,
    }
};

export const getListTransactionMode = (queryparam,successHandler, errorHandler) => {
    return {
        type: actions.GET_LIST_TRANSACTION_MODE,
        queryparam:queryparam,
        successHandler,
        errorHandler,
    }
};

export const submitAddTransactionMode = (payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_ADD_TRANSACTION_MODE,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitEditransactionMode = (id,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_EDIT_TRANSACTION_MODE,
        id:id,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitDeleteransactionMode = (id,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_DELETE_TRANSACTION_MODE,
        id:id,
        successHandler,
        errorHandler,
    }
};

export const getListTransactionCode = (queryparam,successHandler, errorHandler) => {
    return {
        type: actions.GET_LIST_TRANSACTION_CODE,
        queryparam:queryparam,
        successHandler,
        errorHandler,
    }
};

export const getListCharges = (queryparam,successHandler, errorHandler) => {
    return {
        type: actions.GET_LIST_CHARGES,
        queryparam:queryparam,
        successHandler,
        errorHandler,
    }
};

export const submitAddTransactionCode = (payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_ADD_TRANSACTION_CODE,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitDeleteransactionCode = (id,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_DELETE_TRANSACTION_CODE,
        id:id,
        successHandler,
        errorHandler,
    }
};

export const getDataTransactionCode = (queryparam,param,successHandler, errorHandler) => {
    return {
        type: actions.GET_DATA_TRANSACTION_CODE,
        queryparam:queryparam,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const getDataTransactionMode = (queryparam,param,successHandler, errorHandler) => {
    return {
        type: actions.GET_DATA_TRANSACTION_MODE,
        queryparam:queryparam,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitEditransactionCode = (id,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_EDIT_TRANSACTION_CODE,
        id:id,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitDeletetransactionCodeDetail = (id,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_DELETE_CORE_TRANSACTION_DETAIL,
        id:id,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const getTransactionCodeDetail = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_CODE_TRANSACTION_DETAIL,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submittransactionCodeDetail = (param,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_CODE_TRANSACTION_DETAIL,
        param:param,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitEditTransactionCodeDetail = (param,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_EDIT_CODE_TRANSACTION_DETAIL,
        param:param,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitTransactionCodeDetailOverdraft = (param,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_CODE_TRANSACTION_DETAIL_OVERDRAFT,
        param:param,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitEditTransactionCodeDetailOverdraft = (param,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_EDIT_CODE_TRANSACTION_DETAIL_OVERDRAFT,
        param:param,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const getTransactionCodeLimit = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_TRANSACTION_CODE_LIMIT,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitAddTransactionCodeLimit = (payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_ADD_TRANSACTION_CODE_LIMIT,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitDeleteTransactionCodeLimit = (param,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_DELETE_TRANSACTION_CODE_LIMIT,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitEditTransactionCodeLimit = (param,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_EDIT_TRANSACTION_CODE_LIMIT,
        param:param,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitGlRecurring = (payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_GLRECURRING,
        payload:payload,
        successHandler,
        errorHandler,
    }
};
export const submittransactionCodeControl = (param,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_TRANSACTIONCODECONTROL,
        param:param,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const getFeeTrxModeChargesProduct = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_FEE_BY_TRX_MODE_CHARGE_PRODUCT,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const getGLSummaryParameterData = (param,actiontype,successHandler, errorHandler) => {
    return {
        type: actions.GET_GL_SUMMARY_PARAMETER_DATA,
        actiontype:actiontype,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitAddGLSummaryParameter = (param,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_ADD_GL_SUMMARY_PARAMETER,
        payload:payload,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitEditGLSummaryParameter = (param,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_EDIT_GL_SUMMARY_PARAMETER,
        payload:payload,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitDeleteGLSummaryParameter = (param,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_DELETE_GL_SUMMARY_PARAMETER,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const getReportJob = (param,actiontype,successHandler, errorHandler) => {
    return {
        type: actions.GET_REPORT_JOB,
        param:param,
        actiontype:actiontype,
        successHandler,
        errorHandler,
    }
};

export const getGlRecurringData = (param,actiontype,successHandler, errorHandler) => {
    return {
        type: actions.GET_GL_RECURRING_DATA,
        actiontype:actiontype,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitGeneralTrxBulkExcel = (param,payload,to,row,extractresult,listdata,listerr,listdone,dispatch,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_GENERAL_TRX_BULK_EXCEL,
        param:param,
        row:row,
        payload:payload,
        listdata:listdata,
        extractresult:extractresult,
        to:to,
        listerr:listerr,
        dispatch:dispatch,
        listdone:listdone,
        successCallback,
        errorCallback
    }
};

export const getInterBranchSettlementData = (param,actiontype,successHandler, errorHandler) => {
    return {
        type: actions.GET_INTERBRANCH_SETTLEMENT_DATA,
        param:param,
        actiontype:actiontype,
        successHandler,
        errorHandler,
    }
};

export const submitDeleteGLRecurring = (param,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_DELETE_GL_RECURRING,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitInterBranchSettlementData = (param,payload,actiontype,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_INTERBRANCH_SETTLEMENT,
        payload:payload,
        param:param,
        actiontype:actiontype,
        successHandler,
        errorHandler,
    }
};