import {put, call}         from 'redux-saga/effects';
import {fineractHeaderKey, loginUrl, otpUrl, retrieveUserUrl,ChangePasswordUserWebOTPURL,ConfirmPasswordUserWebOTPURL
        ,PasswordValidationURL,getUserProfileURL} from '../../shared/apiUrl';
import * as actions                from '../actions';

import AxiosAuth from '../../Axios-Auth';
import Axios     from '../../Axios-Simpool';
import { getuserid, toLogout,msgErrorHandler } from '../../shared/maskFunc';
import CryptoJS from 'crypto-js';
import { keyEcncrypt,deleteSessionSearchFilter, keyLoginDataLocStorage } from '../../shared/globalConfig';
import { maintenance_min_balance,historylink } from '../../shared/constantKey';
import { dataLoginEnc } from '../../shared/processInfoLogin';

export function* logoutSaga() {
    yield call([localStorage, "removeItem"], "token");
    yield call([localStorage, "removeItem"], "userId");
    // yield call([localStorage, "removeItem"], "tenant");
    yield call([localStorage, "removeItem"], "permissions");
    yield call([localStorage, "removeItem"], "authenticated");
    yield call([localStorage, "removeItem"], "showpermissions");
    yield call([localStorage, "removeItem"], maintenance_min_balance);
    yield call([localStorage, "removeItem"], "officeName");
    yield call([localStorage, "removeItem"], "portalmenu");
    yield call([localStorage, "removeItem"], "showportalmenu");
    yield call([localStorage, "removeItem"], "shouldRenewPassword");
    yield call([localStorage, "removeItem"], "externalid");
    yield call([localStorage, "removeItem"], "officeopeningdate");
    sessionStorage.removeItem('sessionuser');
    yield call([localStorage, "removeItem"], "shownotifloking");
    yield call([localStorage, "removeItem"], "lockingdate");
    yield call([localStorage, "removeItem"], "prodname");
    yield call([localStorage, "removeItem"], "dateSystem");
    sessionStorage.removeItem('iskopkar');
    yield call([localStorage, "removeItem"], historylink);
    // sessionStorage.removeItem('listroles');
    yield call([localStorage, "removeItem"], "listroles");
    sessionStorage.removeItem('tabcashierindex');
    deleteSessionSearchFilter();
    sessionStorage.clear();
    yield call([localStorage, "removeItem"], keyLoginDataLocStorage);
    yield put(actions.logoutSucceed());
}

export function* logoutWithError(action) {
    yield call([localStorage, "removeItem"], "token");
    yield call([localStorage, "removeItem"], "userId");
    // yield call([localStorage, "removeItem"], "tenant");
    yield call([localStorage, "removeItem"], "permissions");
    yield call([localStorage, "removeItem"], "authenticated");
    yield call([localStorage, "removeItem"], "showpermissions");
    yield call([localStorage, "removeItem"], maintenance_min_balance);
    yield call([localStorage, "removeItem"], "officeName");
    yield call([localStorage, "removeItem"], "portalmenu");
    yield call([localStorage, "removeItem"], "showportalmenu");
    yield call([localStorage, "removeItem"], "shouldRenewPassword");
    yield call([localStorage, "removeItem"], "externalid");
    yield call([localStorage, "removeItem"], "officeopeningdate");
    sessionStorage.removeItem('sessionuser');
    yield call([localStorage, "removeItem"], "shownotifloking");
    yield call([localStorage, "removeItem"], "lockingdate");
    yield call([localStorage, "removeItem"], "prodname");
    yield call([localStorage, "removeItem"], "dateSystem");
    sessionStorage.removeItem('iskopkar');
    yield call([localStorage, "removeItem"], historylink);
    // sessionStorage.removeItem('listroles');
    yield call([localStorage, "removeItem"], "listroles");
    sessionStorage.removeItem('tabcashierindex');
    deleteSessionSearchFilter();
    sessionStorage.clear();
    yield call([localStorage, "removeItem"], keyLoginDataLocStorage);
    yield put(actions.logoutWithErrorSuccess(action.error));
}

export function* authUserSaga(action) {
    yield put(actions.authStart());
    const authData = {
        username: action.username,
        password: action.password,
    };
    
    try {
        AxiosAuth.defaults.headers.common[fineractHeaderKey] = action.tenant;
        const response = yield AxiosAuth.post(loginUrl, authData,{timeout:10000}).then(response => response.data);
        if (response?.authenticated) {
            deleteSessionSearchFilter();
            const headername = response.tenantDisplayName ? action.tenant+' - '+response.tenantDisplayName+' ('+response.officeName+')':response.officeName;
            const chipertextuserid = CryptoJS.AES.encrypt(JSON.stringify(response.userId),keyEcncrypt).toString();
            const permissions = CryptoJS.AES.encrypt(JSON.stringify(response.permissions),keyEcncrypt).toString();
            const portalmenu = CryptoJS.AES.encrypt(JSON.stringify(response.menus),keyEcncrypt).toString();
            const lockingdate = response.lockingDateTenant?response.lockingDateTenant.join("-"):'';
            const dateSystem = response.dateSystem?CryptoJS.AES.encrypt(JSON.stringify(response.dateSystem.join("-")),keyEcncrypt).toString():'';
            
            let roles = [];
            if(response.roles){
                roles = listRoles(response.roles);
            }
            const encRoles = CryptoJS.AES.encrypt(JSON.stringify(roles),keyEcncrypt).toString();
            yield localStorage.setItem('listroles', encRoles);
            yield localStorage.setItem('token', response.base64EncodedAuthenticationKey);
            yield localStorage.setItem('tenant', action.tenant);
            yield localStorage.setItem('name', action.username);
            yield localStorage.setItem('userId', chipertextuserid);
            yield localStorage.setItem('officeId', response.officeId);
            yield localStorage.setItem('authenticated', response.authenticated);
            yield localStorage.setItem('permissions', permissions);
            yield localStorage.setItem('showpermissions', response.permissions);
            yield localStorage.setItem('officeName', headername);
            yield localStorage.setItem('officeopeningdate', response.officeOpeningDate);
            // yield localStorage.setItem('officeName', response.officeName);
            yield localStorage.setItem('portalmenu', portalmenu);
            yield localStorage.setItem('showportalmenu', response.menus);
            sessionStorage.setItem('sessionuser',action.username);
            yield localStorage.setItem('shownotifloking', response.isLokingNotif?response.isLokingNotif:false);
            if(lockingdate !== ''){
                yield localStorage.setItem('lockingdate', lockingdate);
            }
            if(dateSystem !== ''){
                yield localStorage.setItem('dateSystem', dateSystem);
            }
            yield put(actions.authSuccess({...response, ...action.tenant}));
            let isnewPassword = response.shouldRenewPassword ? true:false;
            yield localStorage.setItem('shouldRenewPassword', isnewPassword);
            if(isnewPassword){
                yield put(actions.changePasswordUserWebOTPRedux(action.tenant));
            }
            let isKopkar = response.cooperativeType ? response.cooperativeType.id == 1 ? true : false : false;
            sessionStorage.setItem('iskopkar',isKopkar);
            
            response.actionuser = action;
            dataLoginEnc(response);
            // sessionStorage.setItem('listroles',encRoles);
            
        }

    } catch (error) {
        console.log(error);
        //action.errorHandler(error);
        yield call(errorHandler, error, action);
    }
}

const listRoles = (value) => {
    let listroles = [];
    for(let i=0; i < value.length; i++){
        listroles.push(value[i].name);
    }

    return listroles;
}
export function* authOtpSaga(action) {
    yield put(actions.otpStart());
    const authData = {
        username: action.username,
        password: action.password,
    };
    try {

        AxiosAuth.defaults.headers.common[fineractHeaderKey] = action.tenant;
        const response = yield AxiosAuth.post(otpUrl, authData,{timeout:10000}).then(response => response.data);
        const ref = response.transactionReference;
        const expired = response.expiredMillis;
        yield put(actions.otpSuccess(ref,expired));

    } catch (error) {
        yield put(actions.otpError(error));
    }

}

export function* authUserOtpSaga(action) {
    yield put(actions.authStart());
    const authData = {
        username: action.username,
        password: action.password,
        transactionReference: action.ref,
        otpCode: action.otpCode,
        rememberMe: action.rememberMe
    };
    try {
        AxiosAuth.defaults.headers.common[fineractHeaderKey] = action.tenant;
        const response = yield AxiosAuth.post(loginUrl, authData).then(response => response.data);
        // console.log('authUserOtpSaga ',response);
        if (response?.authenticated) {
            deleteSessionSearchFilter();
            const headername = response.tenantDisplayName ? action.tenant+' - '+response.tenantDisplayName+' ('+response.officeName+')':response.officeName;
            const chipertextuserid = CryptoJS.AES.encrypt(JSON.stringify(response.userId),keyEcncrypt).toString();
            const permissions = CryptoJS.AES.encrypt(JSON.stringify(response.permissions),keyEcncrypt).toString();
            const portalmenu = CryptoJS.AES.encrypt(JSON.stringify(response.menus),keyEcncrypt).toString();
            const lockingdate = response.lockingDateTenant?response.lockingDateTenant.join("-"):'';
            const dateSystem = response.dateSystem?CryptoJS.AES.encrypt(JSON.stringify(response.dateSystem.join("-")),keyEcncrypt).toString():'';
            let roles = [];
            if(response.roles){
                roles = listRoles(response.roles);
            }
            const encRoles = CryptoJS.AES.encrypt(JSON.stringify(roles),keyEcncrypt).toString();
            yield localStorage.setItem('listroles', encRoles);
            yield localStorage.setItem('token', response.base64EncodedAuthenticationKey);
            yield localStorage.setItem('tenant', action.tenant);
            yield localStorage.setItem('name', action.username);
            yield localStorage.setItem('userId', chipertextuserid);
            yield localStorage.setItem('officeId', response.officeId);
            yield localStorage.setItem('authenticated', response.authenticated);
            yield localStorage.setItem('permissions', permissions);
            yield localStorage.setItem('showpermissions', response.permissions);
            yield localStorage.setItem('officeName', headername);
            yield localStorage.setItem('portalmenu', portalmenu);
            yield localStorage.setItem('showportalmenu', response.menus);
            yield localStorage.setItem('officeopeningdate', response.officeOpeningDate);
            sessionStorage.setItem('sessionuser',action.username);
            yield localStorage.setItem('shownotifloking', response.isLokingNotif?response.isLokingNotif:false);
            if(lockingdate !== ''){
                yield localStorage.setItem('lockingdate', lockingdate);
            }
            if(dateSystem !== ''){
                yield localStorage.setItem('dateSystem', dateSystem);
            }
            yield put(actions.authSuccess({...response, ...action.tenant}));
            let isnewPassword = response.shouldRenewPassword ? true:false;
            yield localStorage.setItem('shouldRenewPassword', isnewPassword);
            if(isnewPassword){
                yield put(actions.changePasswordUserWebOTPRedux(action.tenant));
            }
            let isKopkar = response.cooperativeType ? response.cooperativeType.id == 1 ? true : false : false;
            sessionStorage.setItem('iskopkar',isKopkar);
            response.actionuser = action;
            dataLoginEnc(response);
            // sessionStorage.setItem('listroles',encRoles);
        }

    } catch (error) {
        // console.log('authUserOtpSaga error',error);
        
        yield call(errorHandler, error, action);
    }
}

function* errorHandler(error, action) {
    console.log('errorHandler request ',error.request)
    console.log('errorHandler code ',error.code)
    if (!error.response && !error.data && !error.errors)
    //"SERVER_DOWN" : "Sorry, our server got a lil problem, please make sure your Tenant Code is correct",
    //"SERVER_DOWN" : "Mohon maaf, ada kendala akses ke system kami, Pastikan kode Tenant yang anda masukkan sudah benar",
        action.errorHandler('SERVER_DOWN');
    else {
        let err = error.response ? error.response.data:error.data ? error.data:error.errors[0];
        if (err.userMessageGlobalisationCode === 'error.msg.web.device.not.registered') {
            yield put(actions.otp(action.username, action.password, action.tenant));
            return null;
        }
        else if (err.userMessageGlobalisationCode === "error.msg.invalid.username.password"
            || err.userMessageGlobalisationCode === "error.msg.invalid.username.login"
            || err.userMessageGlobalisationCode === "error.msg.username.login")
            action.errorHandler('INVALID_CREDENTIALS');
        else if (err.userMessageGlobalisationCode === "error.msg.otp.code.not.valid" || err.userMessageGlobalisationCode === "error.msg.otp.not.valid" || err.userMessageGlobalisationCode === "error.msg.resource.not.found")
            action.errorHandler('INVALID_OTP');
        else if (err.userMessageGlobalisationCode === "error.msg.user.attempt.locked")
            action.errorHandler('USER_LOCKED');
        else if (err.userMessageGlobalisationCode === "error.msg.user.attempt.locked")
            action.errorHandler('USER_LOCKED');
        else if (err.userMessageGlobalisationCode === "validation.msg.validation.errors.exist"){
            if(err.errors.length > 0){
                if(err.errors[0].userMessageGlobalisationCode === "validation.msg.externaluser.password.not.equal.to.repeatPassword"){
                    action.errorHandler('REPEAT_PASSWORD_NOT_EQUAL');
                }
            }
        }else if (err.userMessageGlobalisationCode === "error.msg.password.length.does.not.match")
            action.errorHandler(msgErrorHandler(error));
        else if (err.userMessageGlobalisationCode === "error.msg.password.numeric.does.not.match")
            action.errorHandler(msgErrorHandler(error));
        else if (err.userMessageGlobalisationCode === "error.msg.user.attempt.last.login")
            action.errorHandler('USER_LAST_LOGIN');
        else if (err.userMessageGlobalisationCode === "error.msg.user.temporary.locked")
            action.errorHandler('USER_TEMPRORARY_LOCK');
        else if (err.userMessageGlobalisationCode === "error.msg.not.authenticated")
            action.errorHandler('USER_AUTHENTICATED');
        else if (err.userMessageGlobalisationCode === "error.msg.user.locked")
            action.errorHandler('USER_AUTHENTICATED');
        else
            action.errorHandler(msgErrorHandler(error));
        
    }

    if (error.code === 'ECONNABORTED')
        action.errorHandler(msgErrorHandler('ECONNABORTED'));

    // yield put(actions.authFail(null));
    action.errorHandler('error');
}

export function* authCheckStateSaga(action) {
    const token = yield localStorage.getItem("token");
    const tenant = yield localStorage.getItem("tenant");
    if (!token) {
        yield put(actions.logout());
    } else {
        try {
            const response = yield Axios.get(retrieveUserUrl);
            yield put(actions.retrieveUser({...response.data, ...tenant}));

        } catch (error) {
            console.log(error);
            //yield call(errorHandler, error, action);
        }
    }
}

export function* changePasswordUserWebOTPSaga(action) {
    try {
        const response = yield Axios.get(ChangePasswordUserWebOTPURL).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* confirmPasswordUserWebOTPSaga(action) {
    try {
        const response = yield Axios.post(ConfirmPasswordUserWebOTPURL,action.payload).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successCallback(response);
    }catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(error);
    }

}

export function* getPasswordValidationSaga(action) {
    try {
        const response = yield Axios.get(PasswordValidationURL).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* getUserProfileSaga(action) {
    try {
        const response = yield Axios.get(getUserProfileURL(getuserid())).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* changePasswordUserWebOTPReduxSaga(action) {
    yield put(actions.otpStart());
    try {

        // AxiosAuth.defaults.headers.common[fineractHeaderKey] = action.tenant;
        const response = yield Axios.get(ChangePasswordUserWebOTPURL).then(response => response.data);
        const ref = response.reference;
        const expired = response.expiredMillis;
        yield put(actions.otpSuccess(ref,expired));

    } catch (error) {
        yield put(actions.otpError(error));
    }

}

export function* confirmPasswordUserWebOTPReduxSaga(action) {
    // yield put(actions.authStart());
    const authData = {
        // username: action.username,
        password: action.password,
        repeatPassword:action.repeatpassword,
        reference:action.reference,
        otpCode:action.otpcode
    };
    console.log('authData ',authData);
    try {
        // AxiosAuth.defaults.headers.common[fineractHeaderKey] = action.tenant;
        const response = yield Axios.post(ConfirmPasswordUserWebOTPURL, authData).then(response => response.data);
        if (response?.isSuccess) {
            localStorage.removeItem('shouldRenewPassword');
            yield put(actions.auth(action.username, action.password, action.tenant,successHandler,errorChnageHandler));
            // const chipertextuserid = CryptoJS.AES.encrypt(JSON.stringify(response.userId),keyEcncrypt).toString();
            // yield localStorage.setItem('token', response.base64EncodedAuthenticationKey);
            // yield localStorage.setItem('tenant', action.tenant);
            // yield localStorage.setItem('name', action.username);
            // yield localStorage.setItem('userId', chipertextuserid);
            // yield localStorage.setItem('authenticated', response.authenticated);
            // yield put(actions.authSuccess({...response, ...action.tenant}));
            // yield put(actions.changePasswordUserWebOTPRedux(action.tenant));
            
        }

    } catch (error) {
        console.log(error);
        //action.errorHandler(error);
        yield call(errorHandler, error, action);
    }
}

function* successHandler(error) {

}
function* errorChnageHandler(error) {

}